@import 'assets/styles/variable.scss';
.cart-msg {
	background: $primeryColor;
	padding: 10px 20px;
	h4 {
		color: #fff;
		padding: 0;
		font-weight: 600;
	}
}

.cartpage {
	margin-bottom: 20px;
	table {
		// margin: 0 0 30px;
		tr {
			vertical-align: middle;
		}
	}
	.cart-item-product {
		.cart-image {
			display: block;
			img {
				width: 80px;
				object-fit: cover;
			}
		}
		.cart-item-product-wrap {
			margin-left: 10px;
			h4 {
				font-family: 'Barlow Semi Condensed';
				font-size: 18px;
				font-weight: 600;
				color: $seconderyColor;
				padding-bottom: 0;
				text-align: left;
			}
			.cart-item-remove {
				color: #ff481c;
				font-size: 13px;
				i {
					margin-right: 8px;
				}
			}
		}
	}
	.total-price {
		font-weight: 600;
	}
	.first-box {
		padding-left: 0;
		text-align: left;
	}
	.last-box {
		padding-right: 0;
		text-align: right;
	}
}

.cart-item-quantity {
	.qty-btn {
		background: $primeryColor;
		border: none;
		outline: none !important;
		box-shadow: none;
		color: #fff;
		height: 30px;
		width: 30px;
		line-height: 30px;
	}
	.qty-input-box {
		width: 100px;
		margin: 0 4px;
		.qty-input {
			height: 30px;
			line-height: 30px;
			border-radius: 0px;
			border: 1px solid $primeryColor;
			outline: none !important;
			padding: 0 5px;
			text-align: center;
			// padding-right: 41px;
		}
		.qty-type {
			// position: absolute;
			// right: 0;
			// top: 0;
			height: 30px;
			line-height: 30px;
			background: $primeryColor;
			color: #fff;
			display: block;
			padding: 0 10px;
		}
	}
}

.total-amount {
	p {
		font-weight: 600;
		color: $seconderyColor;
		font-size: 20px;
		line-height: 1;
	}
}

.checkout-btn {
	background-color: #3a3a3b;
	line-height: 2;
	color: #fff !important;
	font-size: 18px;
	padding: 0 25px;
	text-transform: uppercase;
	&:hover {
		background: $seconderyHover;
	}
}

.cmn-a-tag {
	background-color: #3a3a3b;
	line-height: 2.5;
	color: #fff !important;
	font-size: 18px;
	border-radius: 5px;
	padding: 0 25px;
	&:hover {
		background-color: #4b8e20;
	}
}

// For RTL
.style-rtl {
	.first-box {
		text-align: right;
	}
	.last-box {
		text-align: left;
		padding-left: 0;
	}
	.cart-item-product {
		.cart-item-product-wrap {
			margin-left: 0;
			margin-right: 10px;
			h4 {
				text-align: right;
			}
			.cart-item-remove {
				i {
					margin-right: 0;
					margin-left: 8px;
				}
			}
		}
	}
}
.shadow-box {
	background: #fff;
	box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.09);
	padding: 20px;
	border-radius: 10px;
	max-width: 450px;
	margin: 0 auto;
}
