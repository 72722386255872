.datatable-wrap {
	.rdt_Table {
		.rdt_TableBody {
			.rdt_TableRow {
				.rdt_TableCell {
					padding-top: 3px;
					padding-bottom: 3px;
					div {
						&:first-child {
							overflow: visible;
							white-space: unset;
							text-overflow: unset;
						}
					}
				}
			}
		}
	}
}
