.description-img-box {
	max-width: 300px;
	margin: 0 0 30px 0;
}

.description-img {
	input {
		&[type='file'] {
			display: none;
			+ {
				label {
					height: 200px;
					border: 2px dashed #d9d9d9;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					transition: all 0.3s ease-in-out;
					position: relative;
					&:hover {
						border-color: #78bc4c;
					}
					.product-upload-text {
						text-align: center;
						i {
							font-size: 40px;
							color: #78bc4c;
							transition: all 0.3s ease-in-out;
						}
						p {
							margin-top: 10px;
							font-size: 16px;
						}
					}
					.preview-img {
						width: 300px;
						img {
							height: 200px;
							object-fit: contain;
							padding: 10px;
						}
					}
				}
			}
		}
	}
}

.cmn-panel-wrap {
	margin-bottom: 20px;
	.bg-card-headar {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
		.quotes-list-head {
			h4 {
				color: #599731;
			}
		}
	}
}

.quotes-list-head {
	h4 {
		padding-bottom: 0;
		font-size: 22px;
		text-transform: capitalize;
	}
}

.cmn-panel-box {
	.card {
		margin-bottom: 20px;
		.card-header {
			.list-head {
				// color: #599731;
				font-size: 22px;
				text-transform: capitalize;
			}
			.quotes-delete {
				.cmn-btn-tag {
					border: none;
					color: #f00;
					font-size: 16px;
					background: transparent;
				}
			}
		}
		.card-body {
			.description-img-box {
				max-width: 100%;
			}
		}
	}
}

.black-bg {
	background: #06b6d1 !important;
}
