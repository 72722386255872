.checkbox {
	padding-left: 20px;
}
.checkbox label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px;
}
.checkbox label::before {
	content: '';
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #cccccc;
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition:
		border 0.15s ease-in-out,
		color 0.15s ease-in-out;
	-o-transition:
		border 0.15s ease-in-out,
		color 0.15s ease-in-out;
	transition:
		border 0.15s ease-in-out,
		color 0.15s ease-in-out;
}
.checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	left: 0;
	top: 0;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: #555555;
}
.checkbox input[type='checkbox'],
.checkbox input[type='radio'] {
	opacity: 0;
	z-index: 1;
}
.checkbox input[type='checkbox']:focus + label::before,
.checkbox input[type='radio']:focus + label::before {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.checkbox input[type='checkbox']:checked + label::after,
.checkbox input[type='radio']:checked + label::after {
	font-family: 'Font Awesome 5 free';
	content: '\f00c';
	font-weight: 900;
}
.checkbox input[type='checkbox']:disabled + label,
.checkbox input[type='radio']:disabled + label {
	opacity: 0.65;
}
.checkbox input[type='checkbox']:disabled + label::before,
.checkbox input[type='radio']:disabled + label::before {
	background-color: #eeeeee;
	cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
	border-radius: 50%;
}
.checkbox.checkbox-inline {
	margin-top: 0;
}

.checkbox-primary input[type='checkbox']:checked + label::before,
.checkbox-primary input[type='radio']:checked + label::before {
	background-color: #337ab7;
	border-color: #337ab7;
}
.checkbox-primary input[type='checkbox']:checked + label::after,
.checkbox-primary input[type='radio']:checked + label::after {
	color: #fff;
}

.checkbox-danger input[type='checkbox']:checked + label::before,
.checkbox-danger input[type='radio']:checked + label::before {
	background-color: #d9534f;
	border-color: #d9534f;
}
.checkbox-danger input[type='checkbox']:checked + label::after,
.checkbox-danger input[type='radio']:checked + label::after {
	color: #fff;
}

.checkbox-info input[type='checkbox']:checked + label::before,
.checkbox-info input[type='radio']:checked + label::before {
	background-color: #5bc0de;
	border-color: #5bc0de;
}
.checkbox-info input[type='checkbox']:checked + label::after,
.checkbox-info input[type='radio']:checked + label::after {
	color: #fff;
}

.checkbox-warning input[type='checkbox']:checked + label::before,
.checkbox-warning input[type='radio']:checked + label::before {
	background-color: #f0ad4e;
	border-color: #f0ad4e;
}
.checkbox-warning input[type='checkbox']:checked + label::after,
.checkbox-warning input[type='radio']:checked + label::after {
	color: #fff;
}

.checkbox-success input[type='checkbox']:checked + label::before,
.checkbox-success input[type='radio']:checked + label::before {
	background-color: #5cb85c;
	border-color: #5cb85c;
}
.checkbox-success input[type='checkbox']:checked + label::after,
.checkbox-success input[type='radio']:checked + label::after {
	color: #fff;
}

.radio {
	padding-left: 20px;
}
.radio label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px;
}
.radio label::before {
	content: '';
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #cccccc;
	border-radius: 50%;
	background-color: #fff;
	-webkit-transition: border 0.15s ease-in-out;
	-o-transition: border 0.15s ease-in-out;
	transition: border 0.15s ease-in-out;
}
.radio label::after {
	display: inline-block;
	position: absolute;
	content: ' ';
	width: 11px;
	height: 11px;
	left: 3px;
	top: 3px;
	margin-left: -20px;
	border-radius: 50%;
	background-color: #4b8e20;
	-webkit-transform: scale(0, 0);
	-ms-transform: scale(0, 0);
	-o-transform: scale(0, 0);
	transform: scale(0, 0);
	-webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type='radio'] {
	opacity: 0;
	z-index: 1;
}
.radio input[type='radio']:focus + label::before {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.radio input[type='radio']:checked + label::after {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
}
.radio input[type='radio']:disabled + label {
	opacity: 0.65;
}
.radio input[type='radio']:disabled + label::before {
	cursor: not-allowed;
}
.radio.radio-inline {
	margin-top: 0;
}

.radio-primary input[type='radio'] + label::after {
	background-color: #337ab7;
}
.radio-primary input[type='radio']:checked + label::before {
	border-color: #337ab7;
}
.radio-primary input[type='radio']:checked + label::after {
	background-color: #337ab7;
}

.radio-danger input[type='radio'] + label::after {
	background-color: #d9534f;
}
.radio-danger input[type='radio']:checked + label::before {
	border-color: #d9534f;
}
.radio-danger input[type='radio']:checked + label::after {
	background-color: #d9534f;
}

.radio-info input[type='radio'] + label::after {
	background-color: #5bc0de;
}
.radio-info input[type='radio']:checked + label::before {
	border-color: #5bc0de;
}
.radio-info input[type='radio']:checked + label::after {
	background-color: #5bc0de;
}

.radio-warning input[type='radio'] + label::after {
	background-color: #f0ad4e;
}
.radio-warning input[type='radio']:checked + label::before {
	border-color: #f0ad4e;
}
.radio-warning input[type='radio']:checked + label::after {
	background-color: #f0ad4e;
}

.radio-success input[type='radio'] + label::after {
	background-color: #5cb85c;
}
.radio-success input[type='radio']:checked + label::before {
	border-color: #5cb85c;
}
.radio-success input[type='radio']:checked + label::after {
	background-color: #5cb85c;
}

input[type='checkbox'].styled:checked + label:after,
input[type='radio'].styled:checked + label:after {
	font-family: 'Font Awesome 5 free';
	content: '\f00c';
	font-weight: 900;
}
input[type='checkbox'] .styled:checked + label::before,
input[type='radio'] .styled:checked + label::before {
	color: #fff;
}
input[type='checkbox'] .styled:checked + label::after,
input[type='radio'] .styled:checked + label::after {
	color: #fff;
}
