input:focus,
select:focus,
textarea:focus {
	box-shadow: none !important;
}

.selectOption {
	appearance: none;
	background: rgba(255, 255, 255, 0) url(../images/angle-down-solid.svg) center right 10px no-repeat;
	background-size: 13px;
	box-shadow: none;
	padding: 0 30px 0 0;
	border: none;
	width: 100%;
	height: 40px;
}

.selectOption-green {
	appearance: none;
	background: rgba(255, 255, 255, 0) url(../images/angle-green.svg) center right 10px no-repeat;
	background-size: 13px;
	box-shadow: none;
	padding: 0 30px 0 0;
	border: none;
	width: 100%;
	height: 40px;
}

.selectOption2 {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: rgba(255, 255, 255, 0) url(../images/angle-down-solid.svg) center right 10px no-repeat;
	background-size: 10px;
	box-shadow: none;
	padding: 0 15px 0 0;
	border: none;
	width: 100%;
	height: 40px;
}

.input-wrap {
	.input-underline {
		@include input-underline();
		background: transparent;
		border-radius: 0;
		padding: 5px 5px 5px 20px;
		color: $tartiaryColor;
		font-weight: 600;
		height: 45px;
		&::placeholder {
			color: $tartiaryColor;
		}
	}
}

.txtare-wrap {
	.txtare-underline {
		@include input-underline();
		background: transparent;
		border-radius: 0;
		padding: 5px 5px 5px 20px;
		color: $tartiaryColor;
		font-weight: 600;
		&::placeholder {
			color: $tartiaryColor;
		}
	}
}

.sendus-massege-lft-box {
	.input-wrap {
		margin: 15px 0;
	}
}

.input-gap {
	+ {
		.input-gap {
			margin-top: 15px;
		}
	}
}

.select-wrap {
	.select-underline {
		@include input-underline();
		border-radius: 0;
		padding: 5px;
		color: #000;
		font-weight: 600;
		height: 45px;
		&::placeholder {
			color: #000;
		}
		&:focus {
			background-color: transparent !important;
		}
	}
	.add-icon {
		.select-underline {
			padding-left: 40px;
		}
	}
}

.send-msg-btn-wrap {
	margin-top: 30px;
	text-align: center;
	.send-msg-btn {
		background-color: $tartiaryColor;
		border: none;
		outline: none !important;
		box-shadow: none;
		line-height: 2.5;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 15px;
		&:hover {
			background-color: $primeryColor;
		}
	}
}

input[type='number'] {
	appearance: textfield;
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		appearance: none;
		margin: 0;
	}
}

// Admin input style
.admin-lbl {
	font-weight: 700;
	font-size: 14px;
	// text-transform: capitalize;
	line-height: 1;
	color: $tartiaryColor;
	margin-bottom: 5px;
}

.admim-input-wrap {
	margin-bottom: 10px;
	.admim-input-style {
		padding: 5px;
		color: $tartiaryColor;
		font-weight: 600;
		height: 38px;
		&::placeholder {
			font-size: 13px;
			color: $tartiaryColor;
		}
	}
}

.admim-select-wrap {
	margin-bottom: 10px;
	.admim-select-style {
		color: $tartiaryColor;
		font-weight: 600;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 5px;
	}
}

.selectArrowBlk {
	appearance: none;
	background: rgba(255, 255, 255, 0) url(../images/angle-down-solid.svg) center right 10px no-repeat;
	background-size: 8px;
	box-shadow: none;
	padding-right: 30px;
	width: 100%;
	height: 38px;
}

.cmn-btn-wrap {
	.cmn-btn {
		background-color: #4b8e20;
		border: none;
		outline: none !important;
		box-shadow: none;
		line-height: 2.3;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 35px;
		margin: 20px 0;
		text-transform: uppercase;
	}
}

.admim-textare-wrap {
	margin-bottom: 10px;
	.admim-textare-style {
		padding: 5px;
		color: $tartiaryColor;
		font-weight: 600;
		resize: none;
		&::placeholder {
			font-size: 13px;
			color: $tartiaryColor;
		}
	}
}

.front-lbl {
	font-weight: 600;
	margin-bottom: 6px;
}
