.not-found-wrap {
	background-size: cover !important;
	min-height: 100vh;
	.eror-404-text {
		text-align: center;
		h3 {
			font-size: 120px;
			font-weight: 900;
			letter-spacing: 8px;
			line-height: 1;
			padding-bottom: 8px;
			color: #fff;
			text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
		}
		p {
			font-size: 24px;
			color: #fff;
			padding-bottom: 10px;
		}
		.error-bkhome {
			display: inline-block;
			padding: 0 15px;
			height: 34px;
			line-height: 34px;
			font-size: 15px;
			background-color: #599731;
			color: #fff;
			text-transform: uppercase;
			font-weight: 500;
			border-radius: 5px;
			&:hover {
				background-color: #305f10;
			}
		}
	}
}
