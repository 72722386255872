@import 'assets/styles/variable.scss';
.about-content-sec {
	padding: 50px 0;
}

.about-content-text {
	h3 {
		font-size: 45px;
		font-family: 'Barlow Semi Condensed';
		text-transform: uppercase;
	}
	p {
		font-weight: 600;
		+ {
			p {
				margin-top: 15px;
			}
		}
	}
}

// About partner slider Start
.about-prtner-sec {
	padding-bottom: 50px;
}

.prtner-slider {
	.owl-stage-outer {
		padding: 15px 0;
	}
}

.about-partner-box {
	background: #fff;
	box-shadow: 0px 0px 5px 2px rgba(74, 75, 75, 0.12);
	padding: 20px;
	.about-partner-box-lft {
		flex: 0 0 150px;
		max-width: 150px;
		.about-partner-box-img {
			display: block;
			img {
				min-height: 150px;
				max-height: 150px;
				object-fit: cover;
			}
		}
	}
	.about-partner-box-rgt {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		padding-left: 15px;
		p {
			height: 150px;
			overflow-y: auto;
		}
	}
}

.slider-arrow {
	.owl-prev,
	.owl-next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 30px;
		width: 30px;
		line-height: 30px;
		text-align: center;
		background-color: #8f8f90 !important;
		border-radius: 50% !important;
		color: #fff !important;
		font-size: 14px !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	.owl-prev {
		left: -7px;
	}
	.owl-next {
		right: -7px;
	}
}

// About partner slider End
// About Btm Sec Start
.aboutus-btm-sec {
	padding: 50px 0;
	background-size: cover !important;
	position: relative;
	z-index: 1;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(6, 78, 2, 0.82);
		z-index: -1;
	}
}

.sec-heading-two {
	h3 {
		font-size: 50px;
		font-family: 'Barlow Semi Condensed';
		color: #fff;
		font-weight: 600;
		text-transform: uppercase;
	}
	p {
		color: #fff;
		font-weight: 600;
	}
}

.rd-more-btn-wrap {
	margin-top: 40px;
}

.white-btn {
	background-color: #fff;
	line-height: 2.5;
	color: #fff;
	font-size: 16px;
	border-radius: 5px;
	padding: 0 25px;
	color: #135408;
	font-weight: 600;
	text-transform: uppercase;
	border: none;
	&:hover {
		background-color: $primeryColor;
		color: #fff;
	}
}

.get-in-touch {
	background: rgba(100, 195, 96, 0.7);
	padding: 25px;
	.input-wrap {
		margin: 0 0 15px 0;
		.input-underline {
			color: #fff;
			border-bottom-color: #fff;
			&::placeholder {
				color: #fff;
			}
		}
	}
	.txtare-wrap {
		.txtare-underline {
			color: #fff;
			border-bottom-color: #fff;
			&::placeholder {
				color: #fff;
			}
		}
	}
}

// rtl style
.style-rtl {
	.about-partner-box {
		.about-partner-box-rgt {
			padding-left: 0;
			padding-right: 15px;
		}
	}
}
