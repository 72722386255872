@import 'assets/admin/styles/variable.scss';
.box-wrap {
	box-shadow: 0 0 0.875rem 0 rgb(33, 37, 41, 0.05);
	margin-bottom: 24px;
	border: none !important;
	height: 100% !important;
	h1 {
		font-size: 28px;
		color: #599731;
	}
}

.stat {
	align-items: center;
	background: #e3fbd3;
	border-radius: 50%;
	display: flex;
	height: 40px;
	justify-content: center;
	width: 40px;
}

.text-green {
	color: #599731 !important;
}
