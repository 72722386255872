// @import 'assets/admin/styles/styles.scss';
@import 'assets/admin/styles/variable.scss';
footer.admin-footer {
	padding: $spacer $spacer * 0.875;
	direction: ltr;
	background: $footer-bg;
	width: 100vw;
	ul {
		margin-bottom: 0;
	}
	// @include media-breakpoint-down(md) {
	//     width: 100vw;
	// }
}

.ftr-copyrights-text {
	p {
		font-size: 13px;
	}
}
