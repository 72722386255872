@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1204px) {
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.footer {
		.ftr-about {
			.ftr-social {
				li {
					a {
						height: 25px;
						width: 25px;
						line-height: 23px;
					}
				}
			}
		}
	}
	.ftr-menu-box {
		h4 {
			font-size: 14px;
		}
	}
	.ftr-menu-box {
		li {
			a {
				font-size: 12px;
			}
		}
	}
	.footer {
		.ftr-about {
			p {
				font-size: 12px;
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
	.footer {
		padding: 20px 0 0 0;
	}
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
	.footer {
		.ftr-about {
			.ftr-logo {
				max-width: 100px;
			}
			.ftr-social {
				margin: 10px 0;
			}
		}
		.ftr-menu-box {
			h4 {
				margin-top: 10px;
			}
		}
	}
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
