.events-sec {
	padding-bottom: 3rem;
}

.events-box {
	background-color: #fff;
	box-shadow: 0px 5px 16px -9px rgba(0, 0, 0, 0.3);
	padding: 1.25rem;
	border-radius: 10px;
	.events-box-desc {
		h4 {
			padding-bottom: 0;
			a {
				color: #3a3a3b;
				font-size: 16px;
				text-transform: uppercase;
				font-family: 'Barlow Semi Condensed';
				font-weight: 700;
				letter-spacing: 0.7px;
				&:hover {
					color: #4b8e20;
				}
			}
		}
		p {
			// height: 110px;
			overflow-y: auto;
		}
		.events-box-img {
			height: 150px;
			display: block;
			margin-bottom: 1rem;
			img {
				height: 100%;
				object-fit: cover;
			}
		}
		.events-type {
			margin-bottom: 0.5rem;
			display: block;
			font-weight: 600;
			font-family: 'Barlow Semi Condensed';
		}
	}
	.event-organizer {
		font-family: 'Barlow Semi Condensed';
		font-weight: 600;
		.event-organizer-info {
			display: flex;
			flex-wrap: wrap;
			li {
				// flex: 1 0;
				&:not(:last-child) {
					margin-right: 0.5rem;
				}
			}
		}
	}
	.events-book-info {
		margin-top: 0.75rem;
		display: flex;
		justify-content: space-between;
		li {
			font-family: 'Barlow Semi Condensed';
			font-weight: 600;
		}
	}
	+ {
		.events-box {
			margin-top: 1.5rem;
		}
	}
}
.register-event {
	margin-top: 1rem;
	.register-event-reg {
		background-color: #3a3a3b;
		border: none;
		outline: none !important;
		box-shadow: none;
		line-height: 2;
		color: #fff;
		font-size: 16px;
		border-radius: 5px;
		padding: 0 15px;
		text-transform: capitalize;
		&:hover {
			background-color: #4b8e20;
		}
	}
}
