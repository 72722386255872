@import 'assets/styles/variable.scss';
.sendus-massege-wrap {
	.sendus-massege-lft,
	.sendus-massege-rgt {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

.sendus-massege-head {
	h4 {
		font-family: 'Barlow Semi Condensed';
		font-size: 30px;
		font-weight: 600;
		text-align: center;
	}
}

.sendus-massege-lft {
	.sendus-massege-lft-box {
		padding: 30px 30px 30px 40px;
	}
}

.sendus-massege-rgt {
	background-size: cover !important;
	// height: 500px;
	.sendus-massege-inner {
		background: rgba(255, 255, 255, 0.8);
		padding: 20px 30px;
		width: 520px;
		margin: 70px auto;
		.home-contact {
			li {
				color: $tartiaryColor;
				font-size: 16px;
				font-weight: 700;
				a {
					font-size: 16px;
					display: flex;
					color: $tartiaryColor;
					font-weight: 700;
					align-items: center;
				}
				.home-contact-mg {
					height: 55px;
					width: 55px;
					display: flex;
					align-items: center;
					background: $primeryColor;
					border-radius: 50%;
					margin-right: 20px;
					img {
						max-width: 20px;
						margin: 0 auto;
					}
				}
				.home-contact-desc {
					flex-basis: 0;
					flex-grow: 1;
					max-width: 100%;
				}
				+ {
					li {
						margin-top: 20px;
					}
				}
			}
		}
	}
}
.style-rtl {
	.sendus-massege-rgt {
		.sendus-massege-inner {
			.home-contact {
				li {
					.home-contact-mg {
						margin-right: 0;
						margin-left: 20px;
					}
				}
			}
		}
	}
}
