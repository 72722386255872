.payment-receipt {
	position: relative;
	.payment-receipt-img {
		max-width: 350px;
		margin: 0 auto;
		display: block;
	}
	.payment-btn-wrap {
		position: absolute;
		right: 0;
		top: 0;
	}
}
