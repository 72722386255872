.modal-custom {
	// display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5;
	.modal-custom-content {
		background-color: #fff;
		padding: 20px;
		border-radius: 8px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-width: 500px;
		width: 100%;
		.custom-modal-close {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
			color: #f00;
		}
		.modal-content-box {
			h2 {
				font-size: 1.5rem;
			}
		}
	}
}

.style-rtl {
	.modal-custom {
		.modal-custom-content {
			.custom-modal-close {
				right: auto;
				left: 10px;
			}
		}
	}
}
