@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1350px) {
	.order-progress {
		.steps > {
			.step {
				font-size: 12px;
			}
		}
	}
}

@media only screen and (max-width: 1280px) {
	.order-progress {
		.steps > {
			.step {
				font-size: 10px;
			}
		}
	}
}

@media only screen and (max-width: 1135px) {
	.order-progress {
		.steps > {
			.step {
				font-size: 9px;
			}
		}
	}
}
