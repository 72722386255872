@import 'assets/styles/variable.scss';
.forumLeft {
	flex: 1 0 0%;
}

.forumRight {
	flex: 0 0 20rem;
	width: 20rem;
}

.Forum {
	padding: 3rem 0;
}

.forumLeft {
	.forumTab {
		.tab-content {
			.card {
				h5 {
					a {
						display: block;
					}
				}
			}
		}
	}
}

$breakpoints: (
	'max-md': '767px',
	'min-md': '768px',
);
.responsive-tabs {
	.nav {
		margin-bottom: 1.5rem;
		.nav-item {
			&:not(:last-child) {
				margin-right: 1.5rem;
			}
		}
	}
	.nav-pills {
		display: none;
	}
	.nav-link {
		padding: 0.5rem 2.3rem;
		color: #3b3b3b !important;
		border-radius: 3.5rem !important;
		border: #3a3a3b 1px solid !important;
	}
	.nav-pills .nav-link.active,
	.nav-pills .show > .nav-link {
		color: #fff !important;
		background-color: #3a3a3b;
	}
	@media (min-width: map-get($breakpoints, 'min-md')) {
		.nav-pills {
			display: flex;
		}
		.card {
			border: none;
			.card-header {
				display: none;
			}
			.collapse {
				display: block;
			}
		}
	}
	@media (max-width: map-get($breakpoints, 'max-md')) {
		.tab-pane {
			display: block !important;
			opacity: 1;
		}
	}
}

.pbtRightTop {
	margin-bottom: 0.5rem;
}

.pbtRight {
	flex: 1 0 0%;
	padding-left: 1.3rem;
	h5 {
		color: #3a3a3b;
		font-size: 16px;
		padding: 0;
		font-weight: bold;
		margin-right: 1em;
	}
	h4 {
		color: #3a3a3b;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		font-family: 'Barlow Semi Condensed';
		font-weight: 700;
		letter-spacing: 0.7px;
	}
	.textArea {
		width: 100%;
		resize: none;
		border: #cecece 1px solid;
		padding: 0.3rem;
		font-family: 'Red Hat Display';
		border-radius: 10px;
		height: 60px;
		padding-right: 60px;
	}
	.postComment {
		background-color: $seconderyColor;
		color: #fff;
		border-radius: 30px;
		line-height: 2;
		padding: 0 1rem;
		border: none;
		font-weight: bold;
	}
	.post-comment {
		background-color: $seconderyColor;
		color: #fff;
		border-radius: 10px;
		padding: 0 1rem;
		border: none;
		font-weight: bold;
		height: 45px;
		width: 45px;
		position: absolute;
		right: 8px;
		top: 7px;
	}
}

.pbtLeft {
	flex: 0 0 45px;
	width: 45px;
	.profile-pic-wrap {
		flex: 0 0 100%;
		max-width: 100%;
		.name-ltr {
			height: 45px;
			width: 45px;
		}
	}
	span {
		display: block;
		img {
			display: block;
			width: 100%;
			border-radius: 50%;
		}
	}
}

.popularBox {
	border: #e1e1e1 1px solid;
	border-radius: 10px;
	padding: 1rem;
	// margin-bottom: 1.4rem;
	.popularBoxTop {
		background-color: #f9f9f9;
		border-radius: 10px;
		padding: 10px;
	}
	+ {
		.popularBox {
			margin-top: 1.4rem;
		}
	}
}

.comment-wrap {
	margin-left: 30px;
	.comment-box {
		.rplylft {
			flex: 0 0 40px;
			width: 40px;
			img {
				border-radius: 50%;
			}
		}
		.rplyrgt {
			flex: 1 0;
			padding-left: 15px;
			.rplyinfo {
				h5 {
					color: #3a3a3b;
					font-size: 16px;
					padding: 0;
					font-weight: bold;
					.cmnt-time {
						color: #939393;
						font-size: 14px;
						padding: 0;
						font-weight: 400;
						padding-left: 20px;
					}
				}
				p {
					margin-top: 4px;
				}
			}
		}
		.rply-comment {
			background-color: #f6fff1;
			border-radius: 10px;
			padding: 10px;
			margin-top: 10px;
		}
		.create-rply {
			margin-left: 65px;
			ul {
				display: flex;
				li {
					.create-rply-btn {
						background: transparent;
						border: none;
						outline: none !important;
						font-size: 14px;
						text-transform: capitalize;
						i {
							margin-right: 5px;
						}
					}
					&:not(:last-child) {
						margin-right: 20px;
					}
				}
			}
		}
	}
	.reply-boxlist {
		margin-left: 30px;
		max-height: 492px;
		overflow-y: auto;
		padding-right: 6px;
		margin-top: 10px;
		&::-webkit-scrollbar {
			width: 2px;
		}
		.rply-comment {
			background-color: #fbfbfb;
		}
	}
}

.popularBoxWrap {
	max-height: 917px;
	overflow-y: auto;
	padding-right: 10px;
}

.forumRightInner {
	border-style: solid;
	border: #cecece 1px solid;
	padding: 1rem;
	border-radius: 10px;
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
	top: 70px;
	.topContributer {
		margin-bottom: 1.5rem;
	}
	.topContributerBox {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
		.tcbLeft {
			flex: 1 0 0%;
			a {
				display: block;
				color: #3a3a3b;
				font-weight: 700;
				span {
					margin-right: 0.5rem;
					display: inline-block;
					width: 36px;
					img {
						border-radius: 50%;
						display: inline-block;
						width: 100%;
					}
				}
			}
		}
		.tcbRight {
			color: $primeryColor;
			font-weight: 700;
			i {
				color: #3a3a3b;
			}
		}
	}
}

.frequentlyAskedQus {
	margin-top: 1rem;
	h3 {
		font-family: 'Barlow Semi Condensed';
		font-weight: 700;
		letter-spacing: 0.7px;
	}
	h2 {
		padding: 0;
	}
	.accordion-button {
		font-size: 1.2rem;
		color: #212529;
		font-weight: 600;
		border: #999999 1px solid;
		border-radius: 10px !important;
		padding: 0.9rem 2rem 0.9rem 3rem;
	}
	.accordion-item {
		border: none;
	}
	.accordion-collapse {
		.accordion-body {
			border: #ddd 1px solid;
			border-radius: 10px;
			margin: 1rem 0 0;
		}
	}
	.accordion-item + .accordion-item {
		margin-top: 1rem;
	}
	.accordion-body {
		padding: 1.3rem 2rem 1.3rem 3rem;
	}
	.accordion-button:not(.collapsed) {
		background-color: #fff;
		box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
		color: #78bc4c;
	}
	.accordion-button {
		&:focus {
			box-shadow: none;
		}
	}
}

.pbtRightTopRight {
	color: $primeryColor;
	font-weight: 700;
	i {
		color: #3a3a3b;
	}
}

// Details Tab Start
$breakpointsTwo: (
	'max-md': '767px',
	'min-md': '1024px',
);
.raiser-dtls-tab {
	.responsive-tabs-dtls {
		padding: 1rem 0 0 0;
		.nav-pills {
			display: none;
		}
		.nav-item {
			.nav-link {
				background: #bcbcbd;
				font-family: 'Barlow Semi Condensed';
				font-size: 18px;
				height: 40px;
				padding: 0 15px;
				line-height: 40px;
				color: #3a3a3b;
				font-weight: 600;
				&.active {
					background: #3a3a3b;
					color: #fff;
				}
			}
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
		.tab-content {
			.card-body {
				padding-left: 0;
				padding-right: 0;
				padding-bottom: 0;
			}
			.found-dtls-tab-text {
				p {
					font-weight: 600;
					margin-bottom: 10px;
				}
			}
		}
		@media (min-width: map-get($breakpointsTwo, 'min-md')) {
			.nav-pills {
				display: flex;
			}
			.card {
				border: none;
				.card-header {
					display: none;
				}
				.collapse {
					display: block;
				}
			}
		}
		@media (max-width: map-get($breakpointsTwo, 'max-md')) {
			.tab-pane {
				display: block !important;
				opacity: 1;
			}
		}
	}
}

.load-btn-wrap {
	margin: 5px 0 0 30px;
	.load-btn {
		color: #78bc4c;
		background: transparent;
		border: none;
		outline: none !important;
		font-size: 15px;
		text-transform: capitalize;
		text-decoration: underline;
	}
}

.filter-btn-wrap {
	ul {
		margin-bottom: 20px;
		li {
			.filter-btn {
				padding: 0.5rem 2.3rem;
				color: #3b3b3b;
				border-radius: 3.5rem;
				border: #3a3a3b 1px solid;
				text-transform: capitalize;
				background-color: transparent;
				&.active {
					background-color: #3a3a3b;
					color: #fff;
				}
			}
			&:not(:last-child) {
				margin-right: 1.5rem;
			}
		}
	}
}

.more-comments-wrap {
	.more-comments-btn {
		background: transparent;
		border: none;
		outline: none !important;
		font-size: 14px;
		text-transform: capitalize;
	}
}

.comments-wrap-list {
	max-height: 1168px;
	overflow-y: auto;
	padding-right: 6px;
	&::-webkit-scrollbar {
		width: 2px;
	}
}

// Details Tab End
// For RTL
.style-rtl {
	.responsive-tabs {
		.nav {
			.nav-item {
				&:not(:last-child) {
					margin-right: 0;
					margin-left: 1.5rem;
				}
			}
		}
	}
	.pbtRight {
		padding-left: 0;
		padding-right: 1.3rem;
	}
	.forumRightInner {
		.topContributerBox {
			.tcbLeft {
				a {
					span {
						margin-right: 0;
						margin-left: 0.5rem;
					}
				}
			}
		}
	}
	.accordion-header {
		.accordion-button {
			&::after {
				margin-left: 0;
				margin-right: auto;
			}
		}
	}
	.pbtRight {
		h5 {
			margin-right: 0;
			margin-left: 1em;
		}
	}
	.pbtRight {
		.textArea {
			padding-left: 60px;
			padding-right: 0.3rem;
		}
		.post-comment {
			right: auto;
			left: 8px;
		}
	}
	.comment-wrap {
		margin-left: 0;
		margin-right: 30px;
		.comment-box {
			.rplyrgt {
				padding-right: 15px;
				padding-left: 0;
				// .rplyinfo{
				//     h5 {
				//         .cmnt-time{
				//             padding-left: 0;
				//             padding-right: 20px;
				//         }
				//     }
				// }
			}
			.create-rply {
				margin-left: 0;
				margin-right: 65px;
				ul {
					li {
						.create-rply-btn {
							i {
								margin-right: 0;
								margin-left: 5px;
							}
						}
						&:not(:last-child) {
							margin-left: 20px;
							margin-right: 0;
						}
					}
				}
			}
		}
		.reply-boxlist {
			margin-right: 30px;
			margin-left: 0;
			padding-left: 6px;
			padding-right: 0;
		}
	}
	.comments-wrap-list {
		padding-right: 0;
		padding-left: 6px;
	}
}
