@import 'assets/styles/variable.scss';
.how-it-works-sec {
	padding: 50px 0;
}

.sec-heading-who {
	h3 {
		font-size: 40px;
		font-family: 'Barlow Semi Condensed';
		color: #3a3a3b;
		font-weight: 600;
		text-transform: uppercase;
	}
}

.how-it-box-wrap {
	.how-it-box {
		position: relative;
		padding: 0 15px;
		h4 {
			font-family: 'Barlow Semi Condensed';
			font-size: 23px;
			font-weight: 600;
		}
		p {
			font-weight: 600;
			text-align: center;
			font-size: 20px;
			line-height: 1.2;
		}
		.it-box-img {
			display: block;
			max-width: 100px;
			margin: 0 auto 20px;
		}
		.arrow-icon-white,
		.arrow-icon-green {
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
		.arrow-icon-white {
			left: 100%;
			border-top: 16px solid transparent;
			border-left: 14px solid #fff;
			border-bottom: 16px solid transparent;
			z-index: 1;
		}
		.arrow-icon-green {
			border-top: 35px solid transparent;
			border-left: 30px solid #b6f091;
			border-bottom: 35px solid transparent;
		}
		.arrow-sdw {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 18px;
			box-shadow: 6px 0px 9px -7px rgba(0, 0, 0, 80%);
		}
	}
	&:last-child {
		.arrow-icon-white,
		.arrow-icon-green,
		.arrow-sdw {
			display: none;
		}
	}
}
.style-rtl {
	.how-it-box-wrap {
		.how-it-box {
			.arrow-icon-white,
			.arrow-icon-green {
				left: auto;
				right: 100%;
			}
			.arrow-icon-white {
				border-right: 14px solid #fff;
				border-left: none;
			}
			.arrow-icon-green {
				border-right: 30px solid #b6f091;
				border-left: none;
			}
			.arrow-sdw {
				right: auto;
				left: 0;
				box-shadow: -6px 0px 9px -7px rgb(0, 0, 0, 0.8);
			}
		}
	}
}
