@import 'assets/styles/variable.scss';
.quotes-user-sec {
	padding: 50px 0;
	background: #ebebeb;
}

.quotes-user-box {
	background: #fff;
	box-shadow: 0px 0px 5px 2px rgba(74, 75, 75, 0.09%);
	padding: 20px;
	margin: 15px 0;
	.quotes-user-img {
		display: block;
		height: 80px;
		width: 80px;
		margin: 0 auto 10px auto;
		border-radius: 50%;
		overflow: hidden;
		box-shadow: 0px 6px 21px 0px rgba(119, 119, 120, 0.21);
		img {
			height: 100%;
			object-fit: cover;
		}
	}
	h4 {
		color: $primeryColor;
		font-size: 18px;
		text-align: center;
		text-transform: capitalize;
		font-weight: bold;
	}
	.quotes-user-content {
		position: relative;
		padding: 0 30px;
		p {
			text-align: center;
			font-size: 16px;
			font-weight: 500;
		}
		&::after,
		&::before {
			content: '';
			position: absolute;
			height: 20px;
			width: 28px;
		}
		&::after {
			background: url(../../assets/images/rgt.png) no-repeat;
			right: 0;
			bottom: 0;
		}
		&::before {
			background: url(../../assets/images/lft.png) no-repeat;
			left: 0;
			top: 0;
		}
	}
}
