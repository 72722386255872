@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1204px) {
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.how-it-box-wrap {
		.how-it-box {
			p {
				font-size: 16px;
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
	.how-it-works-sec {
		padding: 20px 0;
	}
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
	.how-it-box-wrap {
		.how-it-box {
			.it-box-img {
				max-width: 75px;
			}
			p {
				font-size: 14px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
	.how-it-box-wrap {
		box-shadow: 0px 12px 49px 0px rgba(58, 73, 108, 0.19);
		padding: 10px;
		margin-bottom: 15px;
		.how-it-box {
			.arrow-icon-white {
				display: none;
			}
			.arrow-icon-green {
				display: none;
			}
			.arrow-sdw {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
