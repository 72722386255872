@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
	.navigation ul li {
		margin: 0;
	}
	.sec-heading-who {
		h3 {
			font-size: 27px;
		}
	}
}

@media only screen and (max-width: 1280px) {
	/*--nav--*/
	header .rightPart ul {
		display: block;
	}
	.menu-responsive {
		background: none;
		border: medium none;
		cursor: pointer;
		display: block;
		float: left;
		left: -82px;
		padding: 9px 6px 6px;
		z-index: 99999;
		position: absolute;
		top: 57px;
		transition: all 300ms ease-out;
	}
	.scroll-on {
		.menu-responsive {
			top: 5px;
		}
	}
	.menu-responsive:focus {
		outline: none;
	}
	.menu-responsive.menu_responsiveTo {
		background: #cf0000;
		left: -52px;
		top: 5px;
	}
	.menu-responsive span,
	.menu_responsiveTo span {
		background: #ffffff;
		display: block;
		height: 3px;
		margin-bottom: 3px;
		width: 37px;
	}
	.navigation {
		margin: 0;
		background: #fff;
		display: block;
		position: fixed !important;
		height: 100%;
		top: 0;
		right: 0;
		transition: all 300ms ease-out;
		transform: translateX(260px);
		width: 260px;
		z-index: 9999;
		text-align: left;
	}
	.navigation.left0 {
		transform: translateX(0px);
		box-shadow: 26px 0 22px 41px rgba(0, 0, 0, 0.5);
	}
	.navigation ul.d-flex {
		width: 100%;
		display: block !important;
	}
	.navigation ul ul {
		display: none;
		background: #000000;
		opacity: 1;
		visibility: visible;
	}
	.navigation ul li {
		float: left;
		width: 100%;
		border-top: 1px solid #eee;
		position: relative;
	}
	.navigation ul li a {
		display: block;
		// padding: 15px 15px;
		// color: #000;
	}
	.navigation ul li span {
		cursor: pointer;
		display: block;
		height: 44px;
		line-height: 36px;
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
	}
	.navArea nav ul li a {
		text-align: left;
		line-height: 42px;
		padding: 0 15px 0 27px;
		color: #000;
	}
	.navigation ul li:hover ul {
		display: none;
	}
	.navigation ul li ul.submenu {
		opacity: 1;
		visibility: visible;
		padding: 5px 10px;
		position: static;
		visibility: visible;
		transition: none;
		width: 100%;
		padding: 5px 0 !important;
		-moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
		float: left;
		background: #f6f6f6;
	}
	.navigation ul li ul.submenu li {
		width: 100%;
		padding: 0 9px;
	}
	.navigation ul li ul.submenu li img {
		width: 18px;
	}
	.navigation ul li ul.submenu.aboutDd li img {
		top: -2px;
		width: 18px;
	}
	.navigation ul li.sub_menu_open span::after {
		content: '+';
		left: 0;
		color: #000;
		font-size: 30px;
		margin: auto;
		width: 20px;
		height: 20px;
		opacity: 1;
		position: absolute;
		right: 5px;
		top: -15px;
		bottom: 0;
	}
	.navigation ul li.sub_menu_open span.open::after {
		content: 'x';
		font-size: 22px;
		height: 23px;
		width: 20px;
	}
	.menu_responsiveTo span {
		opacity: 0;
	}
	.menu_responsiveTo:after {
		position: absolute;
		content: 'x';
		top: -1px;
		left: 0;
		right: 0;
		margin: auto;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: normal;
		color: #fff;
		font-size: 21px;
	}
	nav ul li ul li {
		border: none !important;
	}
	nav ul li.sub_menu_open i {
		display: none !important;
	}
	.headerRight {
		display: none !important;
	}
	.srcQuote {
		position: fixed;
		top: 22px;
		right: 80px;
	}
	.homeBann {
		margin-top: 0;
	}
	.topHeader {
		// background: #fff;
		padding: 22px 0;
	}
	.srcQuote ul li:first-child a {
		color: #333;
	}
	.srcQuote ul li a {
		font-size: 16px;
		line-height: 48px;
	}
	.log-in-wrap {
		/* width: 100%; */
		padding: 15px 0;
	}
	/*--nav--*/
	.login-area {
		margin-right: 55px;
	}
	.bannar-text-wrap {
		.bannar-text-box {
			h2 {
				font-size: 50px;
			}
		}
	}
	.rgt-head {
		h4 {
			font-size: 13px;
		}
	}
	// For RTL Header
	.style-rtl {
		.navigation {
			right: auto;
			transform: translateX(-260px);
			width: 260px;
			left: 0;
			&.left0 {
				transform: translateX(0px);
				box-shadow: 26px 0 22px -13px rgba(0, 0, 0, 0.5);
			}
		}
		.menu-responsive {
			left: 300px;
			&.menu_responsiveTo {
				left: 265px;
			}
		}
		.navArea {
			nav {
				ul {
					li {
						a {
							text-align: right;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1204px) {
	.models-tab-information {
		.nav-tabs {
			&.first-tab-list {
				.nav-item {
					.nav-link {
						padding: 5px 10px;
						font-size: 12px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1135px) {
	// .logo {
	//     flex: 0 0 150px;
	//     max-width: 150px;
	//     padding: 0 15px;
	// }
	.model-bg {
		max-width: 300px;
	}
	.product-sell .product-sell-text a {
		padding: 0 20px;
	}
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.sec-heading {
		h3,
		.h3 {
			font-size: 30px;
		}
	}
	.sec-heading-who {
		h3 {
			font-size: 30px;
			text-align: center;
			padding-bottom: 20px !important;
		}
	}
	.bannar-text-wrap {
		.bannar-text-box {
			h2 {
				font-size: 35px;
			}
			p {
				font-size: 15px;
			}
			.bannar-btn {
				margin-top: 30px;
				li {
					a {
						line-height: 2.5;
					}
				}
			}
		}
	}
	.home-slider {
		.owl-nav {
			.owl-prev,
			.owl-next {
				height: 50px;
				width: 50px;
				line-height: 46px;
			}
		}
	}
	.home-model-sec {
		padding: 30px 0;
	}
	.wrap-gap {
		padding-top: 30px;
	}
	.model-bg {
		display: none;
	}
	.become-member-wrap {
		justify-content: center;
	}
	.login-ftr {
		.join-model-btn-wrap {
			.join-model-btn {
				font-size: 15px;
			}
		}
	}
	.wrap-head {
		h3 {
			font-size: 30px;
		}
	}
	.newest-box-wrap {
		.newest-box {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	.chiled-models {
		.chiled-models-wrap {
			.chiled-models-box {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
	.photographe-box {
		margin-bottom: 15px;
	}
	.model-user-details {
		.model-user-info {
			.model-user-info-lft,
			.model-user-info-rgt {
				.model-name {
					h3 {
						font-size: 22px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
	.model-user-details {
		.model-user-img {
			flex: 0 0 260px;
			max-width: 260px;
		}
	}
	.model-details-banner {
		img {
			max-height: 200px;
			min-height: 200px;
		}
	}
	.models-tab-information {
		.nav-tabs {
			&.first-tab-list {
				.nav-item {
					.nav-link {
						padding: 5px 10px;
						font-size: 10px;
					}
					&:not(:last-child) {
						margin-right: 5px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
	.model-user-details {
		.model-user-img {
			flex: 0 0 100%;
			max-width: 100%;
			.mobile-box {
				max-width: 350px;
				margin: 0 auto;
			}
		}
	}
	.model-user-details {
		.model-user-info {
			padding-left: 0;
			.model-user-info-lft,
			.model-user-info-rgt {
				.model-social {
					margin-bottom: 0;
					li {
						a {
							color: #000;
						}
					}
				}
				.model-user {
					margin-top: 10px;
				}
			}
		}
	}
	.book-now-wrap {
		.book-now {
			font-size: 17px;
			line-height: 2;
			padding: 0 10px;
		}
	}
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 767px) {
	.bannar-text-wrap {
		.bannar-text-box {
			h2 {
				font-size: 25px;
				padding-bottom: 5px;
			}
			.bannar-btn {
				li {
					a {
						line-height: 2;
						padding: 0 10px;
						font-weight: 400;
					}
				}
			}
		}
	}
	.newest-box-wrap {
		.newest-box {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.chiled-models {
		.chiled-models-wrap {
			.chiled-models-box {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
	.wrap-head {
		h3 {
			font-size: 20px;
		}
	}
	.heading-icon-wrap {
		margin: 5px 0;
		.heading-icon {
			&::after,
			&::before {
				width: 30px;
			}
		}
	}
	.become-member-wrap {
		.become-member {
			.become-member-head {
				h3 {
					font-size: 20px;
				}
			}
		}
	}
	.member-login-list {
		li {
			font-size: 14px;
		}
	}
	.left-right-40 {
		padding-left: 20px;
		padding-right: 20px;
	}
	.menu-responsive {
		left: -63px;
	}
	.topuser {
		margin-top: -90px;
	}
	.model-user-details {
		.model-user-img {
			.mobile-box {
				max-width: 250px;
				margin: 0 auto;
				img {
					max-height: 250px;
					min-height: 250px;
				}
			}
		}
	}
	.models-tab-information {
		.nav-tabs {
			&.first-tab-list {
				.nav-item {
					margin-bottom: 5px;
					&:not(:last-child) {
						margin-right: 5px;
					}
				}
			}
		}
	}
	.sec-heading {
		h3,
		.h3 {
			font-size: 20px;
		}
		p {
			font-size: 13px;
		}
	}
	.sec-heading-who {
		h3 {
			font-size: 20px;
		}
	}
}

@media only screen and (max-width: 736px) {
	.mob-none {
		display: none;
	}
}

@media only screen and (max-width: 667px) {
	.login-ftr {
		.join-model-btn-wrap {
			.join-model-btn {
				font-size: 12px;
				padding: 0 10px;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.bannar-text-wrap {
		.bannar-text-box {
			width: 65%;
			h2 {
				font-size: 20px;
			}
			p {
				font-size: 13px;
			}
			.bannar-btn {
				margin-top: 10px;
				li {
					a {
						line-height: 2;
						padding: 0 10px;
						font-size: 13px;
					}
				}
			}
		}
	}
	.home-slider {
		.owl-nav {
			.owl-prev,
			.owl-next {
				height: 30px;
				width: 30px;
				line-height: 26px;
				font-size: 20px;
			}
		}
	}
}

@media only screen and (max-width: 575px) {
	.only-mobile {
		position: relative;
		.only-mobile-social {
			display: block;
			border: none;
			box-shadow: none;
			outline: none !important;
			padding: 0 10px;
			background: #fff;
			text-transform: capitalize;
			border-radius: 5px;
		}
		.top-small-menu {
			display: block !important;
			background: #fff;
			position: absolute;
			z-index: 3;
			width: 100px;
			padding: 8px;
			right: 0;
			top: 150%;
			border-radius: 5px;
			box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in-out;
			li {
				a {
					color: #000;
				}
			}
		}
		&:hover {
			.top-small-menu {
				top: 100%;
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.top-rgt-wrap {
		.top-social {
			margin-left: 0;
		}
	}
	.top-rgt-wrap.d-flex.flex-wrap {
		flex-direction: row-reverse;
	}
	.logo {
		flex: 0 0 100px;
		max-width: 100px;
	}
	.log-reg-nav {
		ul {
			a {
				font-size: 12px;
			}
		}
	}
	.login-area {
		margin-right: 20px;
	}
	.chiled-models {
		margin-left: 0;
	}
	.top-rgt-wrap {
		.top-social {
			li {
				a {
					padding: 0 5px;
				}
			}
		}
	}
	.home-rgt-gap {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}

@media only screen and (max-width: 545px) {
	// .menu-responsive {
	//     top: 15px;
	//     left: -48px;
	// }
	.srcQuote ul li:not(:last-child) {
		margin-right: 4px;
	}
	// .menu-responsive span,
	// .menu_responsiveTo span {
	//     width: 30px;
	// }
	.topuser {
		margin-top: -90px;
	}
	.model-user-details {
		.model-user-img {
			.mobile-box {
				max-width: 200px;
				margin: 0 auto;
				img {
					max-height: 200px;
					min-height: 200px;
				}
			}
			.model-follower {
				li {
					a {
						font-size: 12px;
					}
				}
			}
			.model-user-box {
				.model-views {
					font-size: 10px;
				}
			}
		}
		.model-user-info {
			align-items: start !important;
			.model-user-info-lft,
			.model-user-info-rgt {
				.model-name {
					margin-bottom: 10px;
					h3 {
						font-size: 16px;
					}
					.ratting-star {
						margin-top: 0;
					}
				}
				.model-social {
					margin-bottom: 0;
					li {
						a {
							font-size: 14px;
							color: #000;
						}
					}
				}
				.model-user {
					margin-top: 0;
				}
			}
			.book-now-wrap {
				margin-top: 5px;
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	.bannar-text-wrap {
		.bannar-text-box {
			width: 70%;
			h2 {
				font-size: 15px;
			}
			p {
				font-size: 12px;
			}
			.bannar-btn {
				li {
					a {
						font-size: 12px;
					}
				}
			}
		}
	}
	.home-slider {
		.owl-nav {
			.owl-prev {
				left: 12px;
			}
			.owl-nex {
				right: 12px;
			}
		}
	}
	// .topuser {
	//     margin-top: -50px;
	// }
	// .model-user-details {
	//     .model-user-img {
	//         .mobile-box{
	//             max-width: 100px;
	//             margin: 0 auto;
	//             img{
	//                 max-height: 100px;
	//                 min-height: 100px;
	//             }
	//         }
	//     }
	// }
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
