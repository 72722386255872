@import 'assets/styles/variable.scss';
// Data observatory Sec Start
.data-date-time {
	color: $seconderyColor;
	font-size: 16px;
	font-weight: 600;
}

.data-social {
	li {
		a {
			display: block;
			&.fcbk {
				color: #4267b2;
			}
			&.ytbe {
				color: #ff0000;
			}
			&.twtr {
				color: #1da1f2;
			}
			&.lkdn {
				color: #0a66c2;
			}
		}
		&:not(:last-child) {
			margin-right: 20px;
		}
	}
}

.dataobservatory-sec-wrap {
	padding-bottom: 50px;
}

.data-obs-text-wrap {
	h3 {
		font-size: 45px;
		font-family: 'Barlow Semi Condensed';
		font-weight: 600;
		color: $tartiaryColor;
	}
	h4 {
		color: $tartiaryColor;
		font-size: 15px;
	}
	p {
		margin-bottom: 15px;
	}
	h5 {
		color: $primeryColor;
		font-size: 15px;
	}
	ul {
		margin-bottom: 15px;
		li {
			position: relative;
			padding: 8px 0 8px 20px;
			&::before {
				content: '';
				position: absolute;
				height: 2px;
				width: 7px;
				left: 0;
				top: 18px;
				background: #4b8e20;
			}
		}
	}
}

// Data observatory Sec End
// Weather Update Sec Start
.weather-update-wrap {
	.weather-update-head {
		background-color: $tartiaryColor;
		padding: 15px;
		.weather-heading {
			font-size: 25px;
			font-family: 'Barlow Semi Condensed';
			font-weight: 600;
			color: #fff;
		}
		.weather-refree-btn {
			background: transparent;
			border: none;
			outline: none !important;
			box-shadow: none;
			color: #fff;
			font-size: 18px;
		}
	}
	.weather-update-box {
		background-color: #d7d7d7;
		padding: 30px;
		.weather-data-box {
			background-color: #fff;
			padding: 10px;
			border-radius: 8px;
			li {
				color: $tartiaryColor;
				font-weight: 600;
				font-size: 18px;
			}
			h3 {
				font-size: 80px;
				position: relative;
				display: inline-block;
				padding: 0;
				font-family: 'Barlow Semi Condensed';
				line-height: 1;
				.weather-deg {
					position: absolute;
					right: -24px;
					top: 8px;
					background: #fff;
					border: 6px solid #555555;
					height: 20px;
					width: 20px;
					border-radius: 50%;
				}
			}
			p {
				font-weight: 600;
			}
			&.weather-cloud {
				ul {
					li {
						i {
							color: $weatherCloud;
						}
					}
				}
				h3 {
					color: $weatherCloud;
					.weather-deg {
						border-color: $weatherCloud;
					}
				}
			}
			&.weather-sun {
				ul {
					li {
						i {
							color: $weatherSun;
						}
					}
				}
				h3 {
					color: $weatherSun;
					.weather-deg {
						border-color: $weatherSun;
					}
				}
			}
		}
		.weathe-letest {
			display: block;
			text-align: center;
			text-decoration: underline;
			color: #35780a;
			font-size: 15px;
			font-weight: 600;
			margin-top: 20px;
		}
	}
}

// Weather Update Sec End
// Weather Update bottom Start
.data-post-box {
	margin-top: 25px;
	.post-box-img {
		display: block;
	}
	h4 {
		color: #3a3a3b;
		font-size: 15px;
		padding: 10px 0 0 0;
	}
}

// Weather Update bottom End
// For RTL
.style-rtl {
	.data-obs-text-wrap {
		ul {
			li {
				padding-right: 20px;
				padding-left: 0;
				&::before {
					left: auto;
					right: 0;
				}
			}
		}
	}
	.weather-update-wrap {
		.weather-update-box {
			.weather-data-box {
				padding-right: 30px;
			}
		}
	}
	.data-social {
		li {
			&:not(:last-child) {
				margin-right: 0;
				margin-left: 20px;
			}
		}
	}
}

// For RTL End
.letest-event-slider {
	.owl-stage {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.slider-arrow-btm {
	.owl-nav {
		margin-top: 0;
		.owl-prev,
		.owl-next {
			height: 30px;
			width: 30px;
			line-height: 30px;
			text-align: center;
			background-color: #8f8f90 !important;
			border-radius: 50% !important;
			color: #fff !important;
			font-size: 14px !important;
			padding: 0 !important;
		}
	}
}

.upcoming-event {
	margin-top: 1.5rem;
	background-color: #3a3a3b;
	padding: 15px;
	font-size: 25px;
	font-family: 'Barlow Semi Condensed';
	font-weight: 600;
	color: #fff;
	text-transform: capitalize;
}

// .letest-event-slider {
//     .events-box {
//         .events-box-desc {
//             p {
//                 height: 90px;
//             }
//         }
//     }
// }

.reported-list-head {
	font-size: 45px;
	font-family: 'Barlow Semi Condensed';
	font-weight: 600;
	color: #3a3a3b;
	margin-bottom: 1rem;
	text-transform: capitalize;
}

.reported-list-box {
	.reported-box {
		border: #e1e1e1 1px solid;
		border-radius: 10px;
		padding: 1rem;
		h4 {
			color: #3a3a3b;
			font-size: 16px;
			text-transform: uppercase;
			font-family: 'Barlow Semi Condensed';
			font-weight: 700;
			letter-spacing: 0.7px;
		}
		ul {
			display: flex;
			margin-top: 0.5rem;
			li {
				span {
					cursor: pointer;
				}
				strong {
					text-decoration: underline;
					margin-right: 0.25rem;
				}
				&:not(:last-child) {
					margin-right: 1rem;
				}
			}
		}
		+ {
			.reported-box {
				margin-top: 1rem;
			}
		}
	}
}
