// For Header
@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
	.logo {
		flex: 0 0 100px;
		max-width: 100px;
		a {
			.logo-wrap {
				max-width: 70px;
			}
			&::after {
				top: 99%;
			}
		}
	}
	.start-header {
		&.scroll-on {
			.logo {
				.logo-wrap {
					max-width: 65px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1204px) {
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
