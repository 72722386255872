@import 'assets/styles/variable.scss';
.fund-sec {
	padding-bottom: 50px;
}

.fund-page-wrap-lft {
	flex: 0 0 400px;
	max-width: 400px;
}

.fund-page-wrap-rgt {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	padding-left: 20px;
}

.filter-link {
	li {
		a {
			display: block;
			line-height: 1;
			padding: 10px 0;
			font-weight: 500;
			font-size: 16px;
			color: #000;
			&:hover {
				color: $seconderyHover;
			}
		}
	}
}

.search-wrap {
	position: relative;
	.search-input-style {
		border-color: #e1e1e1;
		height: 40px;
		padding: 5px 48px 5px 20px;
		border-radius: 30px;
	}
	.src-icon-wrap {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		.src-icon-btn {
			border: none;
			box-shadow: none;
			outline: none !important;
			background: transparent;
			color: $seconderyColor;
			height: 100%;
			padding: 0 15px;
		}
	}
}

.select-wrap {
	margin: 15px 0;
	.select-wrap-lft {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		.catg-selct {
			.catg-selct-txt {
				padding-right: 6px;
				font-weight: 600;
			}
			.catg-selct-opt {
				width: 150px;
				.selectOption {
					border: 1px solid #e1e1e1;
					height: 30px;
					padding-left: 8px;
					color: $seconderyColor;
				}
			}
			&:not(:last-child) {
				margin-right: 25px;
			}
		}
	}
	.select-wrap-rgt {
		flex: 0 0 20px;
		max-width: 20px;
		.select-rfrs-btn {
			border: none;
			box-shadow: none;
			outline: none !important;
			background: transparent;
			color: #000;
			height: 100%;
			font-size: 20px;
		}
	}
}

// Fund raiser Box
.found-raiser-box {
	.found-raiser-info {
		display: block;
		background-color: #fff;
		box-shadow: 0px 11px 26.97px 2.03px rgba(74, 75, 75, 0.18);
		margin-bottom: 25px;
		.found-raiser-img {
			display: block;
			img {
				max-height: 200px;
				min-height: 200px;
				object-fit: cover;
			}
		}
		.found-raiser-desc {
			padding: 15px;
			p {
				font-weight: 600;
				color: #000;
			}
			h4 {
				color: $seconderyColor;
				font-family: 'Barlow Semi Condensed';
				font-size: 20px;
				span {
					font-size: 18px;
					color: $tartiaryColor;
				}
			}
			.post-fund-raiser {
				display: block;
				color: #979799;
				font-size: 13px;
				font-weight: 600;
				margin: 3px 0;
			}
			.price-donate {
				position: relative;
				width: 100%;
				height: 7px;
				background: #fff;
				border: 1px solid #78bc4c;
				border-radius: 30px;
				// overflow: hidden;
				margin-bottom: 14px;
				margin-top: 20px;
				.donate-bar {
					position: absolute;
					left: 0;
					top: 0;
					background: #78bc4c;
					height: 100%;
					// overflow: hidden;
					border-radius: 30px;
					padding-left: 10px;
					&::after {
						content: '';
						position: absolute;
						height: 15px;
						width: 15px;
						background: #fff;
						border: 1px solid #78bc4c;
						border-radius: 50%;
						right: -1px;
						top: -6px;
					}
				}
				.price-donate-top {
					position: absolute;
					right: -5px;
					top: -26px;
					color: #333333;
					font-size: 14px;
					font-weight: 700;
				}
			}
			ul {
				li {
					color: $tartiaryColor;
					font-size: 13px;
					font-weight: 600;
				}
			}
		}
	}
}

// Fund raiser Box
.apply-fundraise {
	margin-bottom: 20px;
	.apply-fundraise-btn {
		background-color: #4b8e20;
		line-height: 2.5;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 25px;
		border: none;
		box-shadow: none;
		outline: none !important;
		font-family: 'Barlow Semi Condensed';
		font-weight: 600;
		width: 100%;
	}
}

// apply-fundraise-modal Start
.apply-fundraise-modal {
	.modal-header {
		padding: 0.5rem 1rem;
		.modal-title {
			padding-bottom: 0;
			color: #4b8e20;
			font-family: 'Barlow Semi Condensed';
			font-weight: 600;
		}
	}
	.modal-body {
		.input-wrap2 {
			margin-bottom: 10px;
		}
		.txtare-wrap2 {
			margin-bottom: 10px;
			.txtare-style2 {
				resize: none;
			}
		}
	}
}

.green-btn-wrap {
	text-align: center;
	.green-btn {
		background-color: #4b8e20;
		line-height: 2.5;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 25px;
		border: none;
		box-shadow: none;
		outline: none !important;
		font-family: 'Barlow Semi Condensed';
		font-weight: 600;
		text-transform: uppercase;
	}
}

// apply-fundraise-modal End
// Fund Page End
// For RTL
.style-rtl {
	.fund-page-wrap-rgt {
		padding-left: 0px;
		padding-right: 20px;
	}
	.select-wrap {
		.select-wrap-lft {
			.catg-selct {
				&:not(:last-child) {
					margin-right: 0;
					margin-left: 25px;
				}
				.catg-selct-txt {
					padding-right: 0;
					padding-left: 6px;
				}
			}
		}
	}
	.found-raiser-box {
		.found-raiser-info {
			.found-raiser-desc {
				.price-donate {
					.donate-bar {
						left: auto;
						right: 0;
						.price-donate-top {
							right: auto;
							left: -2px;
						}
						&::after {
							right: auto;
							left: -1px;
						}
					}
				}
			}
		}
	}
	.apply-fundraise-modal {
		.modal-header {
			.btn-close {
				margin: 0 auto 0 0 !important;
			}
		}
	}
}

.expired {
	color: red;
}
