/* MESH LOADER START*/

.loader-wrap {
	// display: none;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.9);
	z-index: 9999;
	.mesh-loader-wrap {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		.mesh-loader {
			// overflow: hidden;
			// height: inherit;
			// width: inherit;
			.circle {
				width: 30px;
				height: 30px;
				position: absolute;
				background: #78bc4c;
				border-radius: 50%;
				margin: -15px;
				animation: mesh 3s ease-in-out infinite -1.5s;
				&:last-child {
					animation-delay: 0s;
				}
			}
			.set-one,
			.set-two {
				position: absolute;
				top: 50%;
				left: 50%;
			}
			.set-one {
				transform: rotate(90deg);
			}
		}
	}
}

@keyframes mesh {
	0% {
		-webkit-transform-origin: 50% -100%;
		transform-origin: 50% -100%;
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	50% {
		-webkit-transform-origin: 50% -100%;
		transform-origin: 50% -100%;
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	50.1% {
		-webkit-transform-origin: 50% 200%;
		transform-origin: 50% 200%;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform-origin: 50% 200%;
		transform-origin: 50% 200%;
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* MESH LOADER END*/
