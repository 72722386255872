@import 'assets/styles/variable.scss';

.admim-txtare-wrap {
	.admim-txtare-style {
		resize: none;
	}
}
.box-heading {
	color: $primeryColor;
	font-size: 20px;
	text-transform: capitalize;
	font-weight: bold;
	border-left: 3px solid $primeryColor;
	line-height: 1;
	padding-left: 10px;
	margin-bottom: 10px;
}
.od-details {
	.last-box {
		padding-right: 0;
		text-align: right;
	}
}
.box-style-wrap {
	background-color: #fff;
	box-shadow: 0px 11px 26.97px 2.03px rgba(74, 75, 75, 0.18);
	padding: 15px;
	+ {
		.box-style-wrap {
			margin-top: 20px;
		}
	}
}
.sticky-od {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
	top: 100px;
}
.payment-radio {
	input[type='radio'] {
		display: none;
	}
}
.style-rtl {
	.box-heading {
		border-left: none;
		border-right: 3px solid #4b8e20;
		padding-left: 0;
		padding-right: 10px;
	}
	.payment-radio {
		padding-left: 0;
		padding-right: 20px;
		label {
			&::before {
				left: 0;
				right: 0;
				margin-left: 0;
				margin-right: -20px;
			}
			&::after {
				left: auto;
				right: 3px;
				margin-left: 0;
				margin-right: -20px;
			}
		}
	}
}
