// @import 'assets/styles/style.scss';
@import 'assets/styles/variable.scss';
.partner-home-sec {
	padding: 50px 0;
}

.partner-wrap {
	.partner-box {
		flex: 0 0 20%;
		max-width: 20%;
		margin: 0 15px;
		.partner-box-img {
			display: block;
			img {
				min-height: 180px;
				max-height: 180px;
				object-fit: contain;
			}
		}
	}
}
