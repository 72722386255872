@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
	.donate-btn-wrap {
		.donate-btn {
			font-size: 16px;
			padding: 0 8px;
		}
	}
	.raiser-dtls-tab {
		.responsive-tabs-dtls {
			.nav-item {
				&:not(:last-child) {
					margin-right: 5px;
				}
			}
		}
	}
	.facebook-btn-wrap {
		.facebook-btn {
			font-size: 16px;
			padding: 0 8px;
		}
	}
}

@media only screen and (max-width: 1204px) {
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
	.sec-heading-three {
		h3 {
			font-size: 20px;
		}
	}
	.raiser-dtls-tab {
		.tab-content {
			.card {
				.card-header {
					h5 {
						padding-bottom: 0;
						a {
							color: #212529;
							display: block;
							&:not(.collapsed) {
								color: #4b8e20;
							}
						}
					}
				}
				+ {
					.card {
						margin-top: 10px;
					}
				}
			}
			.tab-pane {
				display: block;
			}
			.fade {
				&:not(.show) {
					opacity: 1;
				}
			}
			.card-body {
				padding: 10px !important;
			}
		}
	}
	.document-download-list {
		.download-list-box {
			a {
				font-size: 60px;
			}
		}
	}
	.image-list {
		.image-list-box {
			img {
				max-height: 90px;
				min-height: 90px;
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.donate-btn-wrap2 {
		.donate-btn2 {
			margin-top: 8px;
		}
	}
	.donate-btn-wrap,
	.facebook-btn-wrap {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.found-sliderbox {
		.image-gallery {
			.image-gallery-content {
				.image-gallery-slide-wrapper {
					.image-gallery-swipe {
						.image-gallery-slides {
							.image-gallery-image {
								max-height: 40vh;
								min-height: 40vh;
							}
						}
					}
				}
			}
		}
	}
	.comments-wrap {
		.comments-wrap-list {
			.wrap-list-rgt {
				.list-rgt-top {
					flex-wrap: wrap;
				}
			}
		}
	}
	.found-sliderbox {
		.image-gallery {
			.image-gallery-content {
				.image-gallery-slide-wrapper {
					.image-gallery-icon {
						height: 40px;
						width: 40px;
						line-height: 40px;
						.image-gallery-svg {
							height: 30px;
							width: 40px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 767px) {
	.donate-wrap {
		margin-top: 15px;
	}
	.donate-btn-wrap2 {
		.donate-btn2 {
			margin-top: 0px;
		}
	}
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
	.donate-btn-wrap2 {
		.donate-btn2 {
			margin-top: 8px;
		}
	}
	.total-donete {
		margin: 10px 0;
		h3 {
			font-size: 30px;
		}
	}
	.raiser-dtls-tab {
		.tab-content {
			.card {
				.card-header {
					h5 {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	.sec-heading-three {
		h3 {
			font-size: 16px;
		}
	}
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
