@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1204px) {
	.fund-page-wrap-lft {
		flex: 0 0 300px;
		max-width: 300px;
	}
}

@media only screen and (max-width: 1135px) {
	.select-wrap {
		.select-wrap-lft {
			.catg-selct {
				.catg-selct-txt {
					font-size: 12px;
				}
				.catg-selct-opt {
					width: 120px;
					.selectOption {
						font-size: 12px;
					}
				}
				&:not(:last-child) {
					margin-right: 15px;
				}
			}
		}
	}
	.style-rtl {
		.catg-selct {
			&:not(:last-child) {
				margin-right: 0;
				margin-left: 15px;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.select-wrap {
		.select-wrap-lft {
			.catg-selct {
				margin: 4px 0;
				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}
	.found-raiser-box {
		.found-raiser-info {
			.found-raiser-desc {
				h4 {
					font-size: 16px;
				}
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 767px) {
	.forumRight {
		flex: 0 0 100%;
		width: 100%;
	}
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
	.funds-ads {
		display: none;
	}
	.fund-page-wrap-lft {
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 15px;
	}
	.fund-page-wrap-rgt {
		padding-left: 0;
	}
	.style-rtl {
		.fund-page-wrap-rgt {
			padding-right: 0;
		}
	}
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
