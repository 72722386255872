// For Home Banner
@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
	.step-arrow-shape {
		ul {
			li {
				padding: 10px 20px;
				.step-arrow-img {
					max-width: 45px;
					margin: 0 auto 5px;
				}
				&:not(:last-child) {
					margin-right: 75px;
				}
			}
		}
	}
	.style-rtl {
		.step-arrow-shape {
			ul {
				li {
					&:not(:last-child) {
						margin-right: 0px;
						margin-left: 75px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1204px) {
	.step-arrow-shape {
		ul {
			li {
				flex: 0 0 23%;
				max-width: 23%;
				margin: 8px 0;
				&:not(:last-child) {
					margin-right: 13px;
				}
				&:nth-child(odd) {
					&::after {
						display: none;
					}
					&::before {
						display: none;
					}
				}
				&:nth-child(even) {
					&::after {
						display: none;
					}
					&::before {
						display: none;
					}
				}
			}
		}
	}
	.style-rtl {
		.step-arrow-shape {
			ul {
				li {
					&:not(:last-child) {
						margin-left: 13px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.hased-platform-sec {
		padding: 20px 0;
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 767px) {
	.step-arrow-shape {
		ul {
			li {
				flex: 0 0 40%;
				max-width: 40%;
			}
		}
	}
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
	.step-arrow-shape {
		ul {
			li {
				&:not(:last-child) {
					margin-right: 0;
				}
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
	.style-rtl {
		.step-arrow-shape {
			ul {
				li {
					&:not(:last-child) {
						margin-left: 0;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
