// Payment Success page start
.payment-page-sec {
	padding: 80px 0;
}

.payment-page {
	text-align: center;
	i {
		font-size: 70px;
	}
	h2 {
		font-size: 30px;
		text-transform: capitalize;
		padding-bottom: 5px;
	}
	p {
		font-size: 15px;
		margin-bottom: 10px;
	}
	a {
		background-color: #000;
		color: #fff !important;
		font-size: 15px;
		text-align: center;
		text-transform: capitalize;
		letter-spacing: 0.5px;
		padding: 0 15px;
		font-weight: 600;
		line-height: 2.5;
		border-radius: 5px;
	}
	&.pay-success {
		i {
			color: green;
		}
	}
}

.order-success-btn-wrap {
	li {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

// Payment Success page end
