.rdt_TableHeadRow {
	background-color: #538036 !important;
	color: #fff !important;
}

.datatable-wrap {
	.rdt_TableCell {
		.admin-user-img {
			display: block;
			height: 60px;
			width: 60px;
			border-radius: 50%;
			margin: 8px 0;
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
