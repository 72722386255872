.fund-img-wrap {
	position: relative;
	flex: 0 0 150px;
	max-width: 150px;
	// background-color: rgb(245 245 245);
	border: 1px solid rgb(160, 157, 157);
	border-radius: 0.375rem;
	overflow: hidden;
	img {
		height: 150px;
		object-fit: contain;
	}
	.image-del {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0;
		box-shadow: none;
		outline: none !important;
		border: none;
		line-height: 1.5;
		font-size: 16px;
		background: #f00;
		padding: 0 8px;
		color: #fff;
	}
	&:not(:last-child) {
		margin-right: 15px;
	}
}

.image-upload-wrap {
	flex: 0 0 150px;
	max-width: 150px;
	.admim-input-wrap {
		position: relative;
		border: 1px solid rgb(160, 157, 157);
		width: 150px;
		height: 150px;
		margin-bottom: 0;
		border-radius: 0.375rem;
		input[type='file'] {
			opacity: 0;
			height: 100%;
		}
		.image-upload-lbl {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			text-align: center;
			font-size: 14px;
			font-weight: 600;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 10px;
			cursor: pointer;
			font-weight: 700;
			i {
				font-size: 40px;
				color: #78bc4c;
				margin-bottom: 5px;
			}
			.doc-count {
				position: absolute;
				bottom: -1px;
				background: #51ad16;
				left: 0;
				width: 100%;
				border-radius: 0 0 0.375rem 0.375rem;
				padding: 4px 0;
				color: #fff;
			}
		}
	}
}

.overfolow-hid {
	overflow: hidden;
}
.fund-raiser-member-add {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	padding: 1rem;
	background-color: #f5f5f5;
	.fund-raiser-member-head {
		background-color: #dff0d8;
		text-align: center;
		padding: 0.5rem 0.325rem;
		color: #3c763d;
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 1;
		text-transform: capitalize;
	}
}
.member-add-box {
	padding: 0.75rem;
	background-color: #fff;
	box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.03);
	position: relative;
	.member-add-box-head {
		background-color: #cfcfcf;
		text-align: center;
		padding: 0.25rem 0.325rem;
		color: #000;
		font-size: 1.25rem;
		line-height: 1;
		text-transform: capitalize;
		margin-bottom: 0.75rem;
	}
	.member-add-box-btn-wrap {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		.member-add-box-btn {
			color: #f00;
			font-size: 1rem;
			background-color: rgba($color: #000000, $alpha: 1);
			box-shadow: none;
			border: none;
			line-height: 1;
			height: 26px;
			width: 26px;
			text-align: center;
			border-radius: 0.325rem;
		}
	}
}
