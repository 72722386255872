.login-regbg {
	min-height: 100vh;
	.login-reg-wrap {
		max-width: 650px;
		margin: 15px;
		width: 100%;
		background-color: #3a3a3b;
		border-radius: 10px;
		.login-reg-box {
			padding: 20px;
			.log-reg-logo {
				display: block;
				max-width: 150px;
				margin: 0 auto 30px;
			}
		}
	}
}

.login-input {
	margin-bottom: 15px;
	.lbl-heading {
		color: #fff;
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 600;
	}
	.login-input-style {
		padding: 5px;
		border: #78bc4c 1px solid !important;
		background: transparent;
		color: #fff !important;
		height: 40px;
		&::placeholder {
			color: #c9c9c9;
			font-size: 14px;
		}
		&:focus {
			background-color: transparent !important;
		}
	}
}

.add-icon {
	position: relative;
	.login-input-style {
		padding-right: 30px;
	}
	.pass-icon {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		color: #78bc4c;
		cursor: pointer;
	}
}

.remember-me {
	input[type='checkbox'] {
		display: none;
	}
	label {
		line-height: 1.2;
		color: #fff;
		text-transform: capitalize;
		&::before {
			border: 1px solid #78bc4c !important;
			background-color: #3a3a3b !important;
		}
		&::after {
			padding-top: 2px !important;
			padding-left: 4px !important;
			color: #78bc4c !important;
			font-size: 10px !important;
		}
	}
}

.forget-pass {
	.forget-pass-btn {
		color: #dfdfdf;
		font-size: 15px;
		text-align: right;
		display: block;
		text-transform: capitalize;
	}
}

.logreg-btn-wrap {
	text-align: center;
	.logreg-btn {
		background-color: #4b8e20;
		border: none;
		outline: none !important;
		box-shadow: none;
		line-height: 2.5;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 35px;
		margin: 20px 0;
		text-transform: uppercase;
		// &:hover{
		//     background-color: #fff;
		//     color: #4b8e20;
		// }
	}
	p {
		color: #fff;
		.logreg-btn2 {
			color: #4b8e20;
			font-size: 18px;
			text-decoration: underline;
		}
	}
}

.style-rtl {
	.forget-pass {
		.forget-pass-btn {
			text-align: left;
		}
	}
}
