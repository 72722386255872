.product-img-wrap {
	max-width: 300px;
	width: 100%;
	margin: 0 auto 30px;
	.product-img-upload {
		input {
			&[type='file'] {
				display: none;
				+ {
					label {
						height: 200px;
						border: 2px dashed #d9d9d9;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						transition: all 0.3s ease-in-out;
						position: relative;
						.product-upload-text {
							text-align: center;
							i {
								font-size: 40px;
								color: #78bc4c;
								transition: all 0.3s ease-in-out;
							}
							p {
								margin-top: 10px;
								font-size: 16px;
							}
						}
						&:hover {
							border-color: #78bc4c;
							.catagory-upload-text {
								text-align: center;
							}
						}
						.preview-img {
							position: absolute;
							left: 0;
							img {
								height: 200px;
								object-fit: contain;
								padding: 10px;
								width: 300px;
							}
						}
					}
				}
			}
		}
	}
}
