.grid-gap {
	margin: 0 -7.5px;
}

.grid-gap > .col,
.grid-gap > [class*='col-'] {
	padding-right: 7.5px;
	padding-left: 7.5px;
}

.left-right-40 {
	padding-left: 40px;
	padding-right: 40px;
}

.sec-heading {
	h3 {
		font-size: 50px;
		font-family: 'Barlow Semi Condensed';
		color: $tartiaryColor;
		font-weight: 600;
		text-transform: uppercase;
		text-align: center;
	}
	p {
		font-weight: 600;
		text-align: center;
		max-width: 850px;
		margin: 0 auto;
	}
}

// data table css
.datatable-wrap {
	.sc-dmRaPn {
		min-height: 200px;
	}
}

// Confirmation popup
.react-confirm-alert-overlay {
	background: rgba(17, 24, 12, 0.7);
}

.custom-confirmation-box {
	background: #11180c;
	padding: 40px;
	border-radius: 10px 0;
	text-align: center;
	width: 350px;
	h1 {
		color: #78bc4c;
		font-size: 34px;
		margin-bottom: 8px;
	}
	p {
		color: #fff;
		font-size: 14px;
		margin-bottom: 8px;
	}
	.no-btn,
	.yes-btn {
		padding: 0 15px;
		height: 30px;
		line-height: 30px;
		border: none;
		outline: none !important;
		box-shadow: none;
		margin: 3px 6px;
		color: #fff;
		font-size: 14px;
	}
	.no-btn {
		background-color: #dc3545;
	}
	.yes-btn {
		background-color: #78bc4c;
	}
}

.cmn-btn-tag-wrap {
	.cmn-btn-tag {
		border: none;
		outline: none !important;
		box-shadow: none;
		line-height: 2;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 20px;
		text-transform: capitalize;
		background: #4b8e20;
	}
}

.cmn-a-tag-wrap {
	.cmn-a-tag {
		line-height: 2;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 20px;
		text-transform: capitalize;
		background: #4b8e20;
	}
}

.cmn-modal {
	.modal-header {
		padding-top: 10px;
		padding-bottom: 10px;
		.modal-title {
			padding-bottom: 0;
		}
		.btn-close {
			background: none;
			padding: 0;
			position: absolute;
			top: 12px;
			right: 15px;
			color: #f00;
			opacity: 1;
		}
	}
}

.cmn-blk-modal {
	.modal-content {
		background-color: #3a3a3b;
	}
	.modal-header {
		padding-top: 10px;
		padding-bottom: 10px;
		.modal-title {
			padding-bottom: 0;
			color: #fff;
		}
		.btn-close {
			background: none;
			padding: 0;
			position: absolute;
			top: 12px;
			right: 15px;
			color: #f00;
			opacity: 1;
		}
	}
	.modal-body {
		.logreg-btn-wrap {
			p {
				a {
					margin-left: 10px;
				}
			}
		}
	}
}

.admin-white-box {
	width: 100%;
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.03);
}

.no-banner {
	padding-top: 75px;
}

.custom-paginate {
	.page-link.active,
	.active > .page-link {
		color: #fff;
		background-color: #4b8e20;
		border-color: #4b8e20;
	}
	.page-link {
		box-shadow: none !important;
	}
}

// Inner Bannar Start
.inner-banner-sec {
	background-size: cover !important;
	height: 150px;
	position: relative;
	z-index: 1;
	.inner-banner-head {
		h3 {
			font-size: 50px;
			font-family: 'Barlow Semi Condensed';
			color: #fff;
			padding: 0;
			// text-transform: capitalize;
		}
	}
	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(43, 89, 14, 0.35);
		z-index: -1;
	}
}

// Inner Bannar End
.sec-gap {
	padding: 3rem 0;
}

// scroll bar css
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 0, 0.3);
	-moz-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
	box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
	background-color: #fff;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
}

::-webkit-scrollbar {
	width: 5px;
	background-color: #fff;
	height: 4px;
}

::-webkit-scrollbar-thumb {
	background-color: #78bc4c;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.simplebar-scrollbar {
	width: 8px;
	&::before {
		background-color: #599731 !important;
		opacity: 1 !important;
	}
}

.resize-none {
	resize: none;
}

.file-scroll {
	overflow-x: auto;
	padding-bottom: 10px;
	// &::-webkit-scrollbar {
	//     height: 4px;
	// }
}

.color-green {
	color: #4b8e20;
}

// .table-responsive {
//     &::-webkit-scrollbar {
//         height: 4px;
//     }
// }
