@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1204px) {
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.responsive-tabs {
		.nav-link {
			padding: 6px;
			border-radius: 5px !important;
		}
		.nav {
			.nav-item {
				&:not(:last-child) {
					margin-right: 0.5rem;
				}
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
	.responsive-tabs {
		.fade:not(.show) {
			opacity: 1;
		}
		.forumLeft {
			.forumTab {
				.tab-content {
					.card {
						h5 {
							padding-bottom: 0;
							a {
								color: #212529;
							}
						}
						+ {
							.card {
								margin-top: 1rem;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.forumRight {
		flex: 0 0 100%;
		width: 100%;
	}
	.forumRightInner {
		.top-contributer-list {
			max-height: 300px;
			overflow-y: auto;
			padding-right: 6px;
		}
	}
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
	.forumRight {
		flex: 0 0 100%;
		margin-top: 15px !important;
	}
	.frequentlyAskedQus {
		.accordion-button {
			padding: 0.9rem 1rem;
			font-size: 16px;
		}
	}
	.responsive-tabs {
		.forumLeft {
			.forumTab {
				.tab-content {
					.card {
						h5 {
							a {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
	.select-wrap {
		.select-wrap-lft {
			.catg-selct {
				.catg-selct-opt {
					width: 100px;
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
