.list-table-img {
	display: block;
	max-width: 120px;
	height: 90px;
	margin: 15px auto 15px 0;
	overflow: hidden;
	img {
		height: 100%;
	}
}
