// @import 'assets/styles/style.scss';
@import 'assets/styles/variable.scss';
.footer {
	background: $primeryColor;
	padding: 40px 0 0 0;
	.ftr-about {
		.ftr-logo {
			display: block;
			max-width: 140px;
			// background: #fff;
			// padding: 20px;
			// border-radius: 0 0 5px 5px;
		}
		p {
			color: #fff;
			font-size: 14px;
			margin: 10px 0;
		}
		.ftr-contact {
			li {
				padding: 5px 0;
				a {
					color: #fff;
					display: block;
					font-size: 13px;
					i {
						margin-right: 8px;
					}
				}
			}
		}
		.ftr-social {
			margin-top: 30px;
			li {
				a {
					color: #fff;
					display: block;
					font-size: 13px;
					padding: 0;
					height: 35px;
					width: 35px;
					line-height: 33px;
					text-align: center;
					i {
						line-height: inherit;
					}
				}
				&:not(:last-child) {
					margin-right: 15px;
				}
			}
		}
	}
}

.ftr-btm {
	padding: 20px 0;
	margin-top: 30px;
	background: $seconderyColor;
	.ftr-btm-text {
		p {
			color: #fff;
			font-size: 14px;
			a {
				color: #173602;
				font-weight: 600;
				font-size: 16px;
				margin-left: 6px;
			}
		}
	}
}

.ftr-menu-box {
	// padding-top: 40px;
	h4 {
		font-size: 20px;
		text-transform: uppercase;
		color: #fff;
		font-family: 'Barlow Semi Condensed';
	}
	li {
		position: relative;
		a {
			color: #fff;
			display: block;
			font-size: 13px;
			padding: 2px 0;
			.dates {
				display: block;
				font-size: 11px;
			}
			i {
				margin-right: 8px;
			}
		}
		// + {
		//     li{
		//         &::before{
		//             content: "";
		//             position: absolute;
		//             left: 0;
		//             width: 50%;
		//             height: 1px;
		//             background: #fff;
		//         }
		//     }
		// }
	}
	.cntct-info {
		li {
			a {
				display: flex;
				i {
					line-height: 1.6;
				}
			}
		}
	}
}
.style-rtl {
	.ftr-menu-box {
		li {
			a {
				i {
					margin-right: 0px;
					margin-left: 8px;
				}
			}
		}
	}
	.footer {
		.ftr-about {
			.ftr-social {
				li {
					&:not(:last-child) {
						margin-right: 0px;
						margin-left: 15px;
					}
				}
			}
		}
	}
}
