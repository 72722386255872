// @import 'assets/styles/style.scss';
@import 'assets/styles/variable.scss';
.hasd-bg {
	background-size: cover !important;
	position: relative;
	z-index: 1;
	&::after {
		content: '';
		position: absolute;
		background: rgba(255, 255, 255, 0.95);
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		z-index: -1;
	}
}

.step-arrow-shape {
	ul {
		li {
			flex: 0 0 13%;
			max-width: 13%;
			padding: 20px;
			position: relative;
			z-index: 1;
			margin: 14px 0;
			.step-arrow-img {
				display: block;
				max-width: 50px;
				margin: 0 auto 15px;
			}
			p {
				text-align: center;
				font-size: 13px;
				font-weight: 500;
				color: #fff;
			}
			&:nth-child(odd) {
				background-color: #599731;
				&::after,
				&::before {
					content: '';
					position: absolute;
					top: 0;
					height: 101%;
					width: 100%;
					background-color: #599731;
					z-index: -1;
				}
				&::after {
					clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
					left: 25%;
				}
				&::before {
					clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
					left: -25%;
				}
			}
			&:nth-child(even) {
				background-color: #999999;
				&::after,
				&::before {
					content: '';
					position: absolute;
					top: 0;
					height: 101%;
					width: 100%;
					background-color: #999999;
					z-index: -1;
				}
				&::after {
					clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
					left: 25%;
				}
				&::before {
					clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
					left: -25%;
				}
			}
			&:not(:last-child) {
				margin-right: 100px;
			}
			.step-srl {
				position: absolute;
				left: 5px;
				top: 5px;
				h4 {
					padding: 0;
					font-weight: 800;
					font-size: 24px;
					color: #fff;
				}
			}
		}
	}
}
.style-rtl {
	.step-arrow-shape {
		ul {
			li {
				.step-srl {
					left: auto;
					top: 5px;
					right: 5px;
				}
				&:not(:last-child) {
					margin-right: 0px;
					margin-left: 100px;
				}
				&:nth-child(odd) {
					&::after {
						clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
						left: 25%;
						transform: rotate(180deg);
					}
					&::before {
						clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
						left: -25%;
						transform: rotate(180deg);
					}
				}
				&:nth-child(even) {
					&::after {
						clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
						left: 25%;
						transform: rotate(180deg);
					}
					&::before {
						clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
						left: -25%;
						transform: rotate(180deg);
					}
				}
			}
		}
	}
}

.rotate-wrap {
	margin: 100px 0;
	.rotate-box {
		width: 200px;
		height: 200px;
		position: relative;
		z-index: 1;
		transform: scale(1);
		transition: all 0.3s ease-in-out;
		&::after {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			transform: rotate(45deg);
			height: 100%;
			width: 100%;
			z-index: -1;
		}
		&.rt-bg1 {
			&::after {
				background: #4b8e20;
			}
			.step-number {
				h4 {
					color: #4b8e20;
				}
			}
		}
		&.rt-bg2 {
			&::after {
				background: #7b8376;
			}
			.step-number {
				h4 {
					color: #7b8376;
				}
			}
		}
		&.rt-bg3 {
			&::after {
				background: #a2a4a0;
			}
			.step-number {
				h4 {
					color: #a2a4a0;
				}
			}
		}
		&.rt-bg4 {
			&::after {
				background: #78bc4c;
			}
			.step-number {
				h4 {
					color: #78bc4c;
				}
			}
		}
		+ {
			.rotate-box {
				margin-left: 103px;
			}
		}
		&:hover {
			z-index: 2;
			transform: scale(1.2);
			&::after {
				box-shadow: 0px 0px 39.1px 6.9px rgba(89, 89, 89, 0.41);
			}
		}
	}
	.row {
		+ {
			.row {
				margin-top: -50px;
			}
		}
	}
	.rotate-box-text {
		.step-number {
			position: absolute;
			top: -11%;
			left: 46%;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			h4 {
				padding: 0;
				font-weight: 800;
				font-size: 24px;
			}
			&::after {
				position: absolute;
				display: block;
				content: '';
				height: 35px;
				width: 35px;
				background: #fff;
				transform: rotate(45deg);
				z-index: -1;
			}
		}
		.rotate-box-img {
			display: block;
			max-width: 60px;
			margin: 0 auto 15px auto;
		}
		p {
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			color: #fff;
		}
		&.box1 {
			.step-number {
				left: 48%;
			}
		}
	}
}

.hased-platform-sec {
	padding: 50px 0;
}
