@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
	.catagory-list-wrap {
		ul {
			li {
				&:not(:last-child) {
					margin-right: 8px;
				}
				a {
					padding: 0 10px;
					font-size: 13px;
					border-radius: 10px;
				}
			}
		}
	}
	.market-page-wrap-lft {
		flex: 0 0 300px;
		max-width: 300px;
	}

	.style-rtl {
		.catagory-list-wrap {
			ul {
				li {
					&:not(:last-child) {
						margin-right: 0px;
						margin-left: 8px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1204px) {
	.product-box {
		.product-desc {
			h4 {
				font-size: 20px;
			}
		}
	}
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
	.catagory-list-sec {
		padding-bottom: 20px;
	}
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.catagory-list-wrap {
		ul {
			flex-wrap: wrap;
			li {
				flex: 0 0 32%;
				max-width: 32%;
				margin: 6px 0;
				.submenu {
					min-width: 100%;
					li {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}
			}
		}
	}
	.catagory-box {
		padding: 10px;
	}
	.reset-list {
		margin-bottom: 10px;
		li {
			.reset-filter {
				font-size: 15px;
			}
		}
	}
	.filter-acrodian {
		.accordion-item {
			.accordion-header {
				.accordion-button {
					padding: 10px;
					font-size: 16px;
				}
			}
			+ {
				.accordion-item {
					margin-top: 8px;
				}
			}
		}
	}
	.filter-chk {
		li {
			&.checkbox {
				padding: 5px 0;
				font-size: 14px;
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
	.product-box {
		.product-desc {
			h4 {
				font-size: 18px;
			}
			.buy-now-btn {
				line-height: 2;
				font-size: 16px;
				padding: 0 15px;
			}
		}
	}
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 767px) {
	// Catagory Left Bar
	.market-page-wrap-lft {
		flex: 0 0 0;
		max-width: 0;
	}
	.market-page-wrap-rgt {
		padding-left: 0;
	}
	.mobile-filter-wrap {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 4;
		left: 0;
		&.top-sticky {
			position: fixed;
			top: auto;
		}
		.mobile-filter-btn {
			display: block;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
			background: rgba(0, 0, 0, 0.7);
			border-radius: 10px;
			height: 36px;
			padding: 0 15px;
			color: #fff;
			border: none;
			outline: none !important;
			box-shadow: none;
			font-size: 15px;
			transition: all 0.3s ease-in-out;
			.first-i,
			.second-i {
				i {
					margin-right: 8px;
				}
			}
			.second-i {
				display: none;
			}
			&.open_filter {
				.first-i {
					display: none;
				}
				.second-i {
					display: block;
				}
			}
		}
		.mobile-filter {
			position: absolute;
			max-height: 400px;
			overflow-y: auto;
			width: 85%;
			bottom: 50px;
			left: 50%;
			transform: translate(-50%, 0px);
			background: #fff;
			padding: 15px;
			border-radius: 10px;
			box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
			opacity: 1;
			visibility: visible;
			transition: all 0.5s ease-in-out;
			.catagory-box {
				background: #fff;
				+ {
					.catagory-box {
						margin-top: 0;
					}
				}
			}
			&.mobile_filter_open {
				opacity: 0;
				visibility: hidden;
				transform: translate(-50%, 256px);
			}
		}
	}
	// Catagory Left Bar
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
	.catagory-list-wrap {
		ul {
			li {
				flex: 0 0 45%;
				max-width: 45%;
			}
		}
	}
	// .market-page-wrap-lft {
	//     flex: 0 0 0;
	//     max-width: 0;
	// }
	// .market-page-wrap-rgt {
	//     padding-left: 0;
	// }
	// .mobile-filter-wrap{
	//     position: fixed;
	//     bottom: 0;
	//     width: 100%;
	//     z-index: 4;
	//     left: 0;
	//     &.top-sticky{
	//         position: fixed;
	//         top: auto;
	//     }
	//     .mobile-filter-btn{
	//         display: block;
	//         position: absolute;
	//         left: 50%;
	//         transform: translateX(-50%);
	//         bottom: 0;
	//         background: rgba(0, 0, 0, 0.7);
	//         border-radius: 10px;
	//         height: 40px;
	//         padding: 0 15px;
	//         color: #fff;
	//         border: none;
	//         outline: none !important;
	//         box-shadow: none;
	//         font-size: 20px;
	//         transition: all .3s ease-in-out;
	//         .first-i,
	//         .second-i{
	//             i{
	//                 margin-right: 8px;
	//             }
	//         }
	//         .second-i{
	//             display: none;
	//         }
	//         &.open_filter{
	//             .first-i{
	//                 display: none;
	//             }
	//             .second-i{
	//                 display: block;
	//             }
	//         }
	//     }
	//     .mobile-filter{
	//         position: absolute;
	//         height: 400px;
	//         overflow-y: auto;
	//         width: 85%;
	//         bottom: 50px;
	//         left: 50%;
	//         transform: translate(-50%, 0px);
	//         background: #fff;
	//         padding: 15px;
	//         border-radius: 10px;
	//         box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
	//         opacity: 1;
	//         visibility: visible;
	//         transition: all 0.5s ease-in-out;
	//         .catagory-box {
	//             background: #fff;
	//             +{
	//                 .catagory-box{
	//                     margin-top: 0;
	//                 }
	//             }
	//         }
	//         &.mobile_filter_open{
	//             opacity: 0;
	//             visibility: hidden;
	//             transform: translate(-50%, 256px);
	//         }
	//     }
	// }
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
