// For Home Banner
@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1204px) {
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.value-proces {
		width: 260px;
		height: 260px;
		.value-box {
			.value-box-text {
				flex: 0 0 200px;
				max-width: 200px;
				p {
					font-size: 12px;
				}
				&.gap-rgt {
					p {
						padding-right: 14px;
					}
				}
				&.gap-lft {
					p {
						padding-left: 14px;
					}
				}
			}
			.value-box-icon {
				flex: 0 0 50px;
				max-width: 50px;
				.value-icon {
					width: 50px;
					height: 50px;
					img {
						max-width: 20px;
					}
				}
			}
			&.first {
				top: 2.4%;
				left: -69%;
			}
			&.second {
				top: 41.6%;
				left: -85%;
			}
			&.third {
				bottom: 2%;
				left: -65.5%;
			}
			&.fifth {
				bottom: 21%;
				left: calc(100% - 17%);
			}
			&.sixth {
				bottom: 60.6%;
				left: calc(100% - 16.3%);
			}
			&.seventh {
				top: -7.6%;
				left: calc(100% - 52%);
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
	.for-dsk {
		display: none;
	}
	.value-proces {
		&.for-mobile {
			display: block;
			.value-box {
				+ {
					.value-box {
						&::after {
							left: 19px;
						}
					}
				}
			}
		}
		.value-box {
			.value-box-text {
				p {
					font-size: 16px;
				}
				&.gap-rgt {
					p {
						padding-right: 14px;
					}
				}
				&.gap-lft {
					p {
						padding-left: 14px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.our-value-sec {
		padding: 20px 0;
	}
	.value-proces {
		margin: 10px auto 30px;
	}
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 736px) {
	.value-proces {
		width: 150px;
		height: 150px;
		&.for-mobile {
			display: block;
			.value-box {
				+ {
					.value-box {
						&::after {
							left: 9px;
						}
					}
				}
			}
		}
		.value-box {
			.value-box-text {
				flex: 0 0 170px;
				max-width: 170px;
				p {
					font-size: 14px;
				}
				&.gap-rgt {
					p {
						padding-right: 12px;
					}
				}
				&.gap-lft {
					p {
						padding-left: 12px;
					}
				}
			}
			.value-box-icon {
				flex: 0 0 30px;
				max-width: 30px;
				.value-icon {
					width: 30px;
					height: 30px;
					img {
						max-width: 14px;
					}
				}
			}
			&.first {
				top: 2.4%;
				left: -105%;
			}
			&.second {
				top: 41.6%;
				left: -123%;
			}
			&.third {
				bottom: 2%;
				left: -102.5%;
			}
			&.fourth {
				bottom: -19%;
				left: calc(100% - 49%);
			}
			&.fifth {
				bottom: 21%;
				left: calc(100% - 17%);
			}
			&.sixth {
				bottom: 60%;
				left: calc(100% - 16.3%);
			}
			&.seventh {
				left: calc(100% - 49%);
			}
		}
	}
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
	.our-value-sec {
		padding: 20px 0;
	}
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
