// @import 'assets/styles/style.scss';
@import 'assets/styles/variable.scss';
.bannar-text-wrap {
	position: relative;
	img {
		height: 500px;
		min-height: 500px;
		object-fit: cover;
	}
	.bannar-text-box {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		width: 70%;
		z-index: 1;
		h2 {
			font-family: 'Barlow Semi Condensed';
			font-size: 60px;
			color: #fff;
			// text-transform: uppercase;
			font-weight: 600;
			line-height: 1;
			text-align: center;
			text-shadow: 0px 11px 22.32px rgba(74, 75, 75, 0.41);
		}
		.bannar-btn {
			margin-top: 60px;
			li {
				a {
					font-family: 'Barlow Semi Condensed';
					line-height: 2.5;
					border-radius: 10px;
					display: block;
					padding: 0 30px;
					font-weight: 600;
					text-transform: uppercase;
					color: $primeryColor;
					background: #fff;
					letter-spacing: 0.5px;
					font-size: 20px;
					&:hover {
						background: $seconderyHover;
						color: #fff;
					}
				}
				&:not(:last-child) {
					margin-right: 25px;
				}
			}
		}
	}
	&::after {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		background: rgba(43, 89, 14, 0.4);
	}
}

.style-rtl {
	.bannar-text-box {
		.bannar-btn {
			li {
				&:not(:last-child) {
					margin-right: 0px;
					margin-left: 25px;
				}
			}
		}
	}
}

.home-slider {
	.owl-nav {
		.owl-prev,
		.owl-next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: #fff;
			text-align: center;
			font-size: 50px;
			background: transparent !important;
		}
		.owl-prev {
			left: 25px;
		}
		.owl-next {
			right: 25px;
		}
		.disabled {
			display: none;
		}
	}
}
