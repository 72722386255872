@import 'assets/admin/styles/variable.scss';
.wrapper {
	align-items: stretch;
	display: flex;
	width: 100%;
	background: $sidebar-bg;
}

// .main {
//     display: flex;
//     width: 100%;
//     min-width: 0;
//     min-height: 100vh;
//     transition: $sidebar-transition;
//     background: $body-bg;
//     flex-direction: column;
//     overflow: hidden;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
// }
.main {
	background: #f5f7fb;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	min-width: 0;
	overflow: hidden;
	transition:
		margin-left 0.35s ease-in-out,
		left 0.35s ease-in-out,
		margin-right 0.35s ease-in-out,
		right 0.35s ease-in-out;
	width: 100%;
}

.content {
	padding: $content-padding-mobile;
	flex: 1;
	width: 100%;
	max-width: 100vw;
	direction: ltr;
	// @include media-breakpoint-up(md) {
	//     width: auto;
	//     max-width: auto;
	// }
	// @include media-breakpoint-up(lg) {
	//     padding: $content-padding-desktop;
	// }
}

.datatable-wrap {
	.order-id-text {
		color: #78bc4c;
		font-weight: 1000;
	}
}
