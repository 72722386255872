.profile-pic-wrap {
	flex: 0 0 40px;
	max-width: 40px;
	.name-ltr {
		display: block;
		width: 40px;
		height: 40px;
		text-align: center;
		border-radius: 50%;
		background-color: #e1e1e1;
		color: #78bc4c;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 600;
		font-family: 'Barlow Semi Condensed';
		padding: 5px;
	}
	.profile-pic {
		img {
			height: 40px;
			width: 40px;
			border-radius: 50%;
			object-fit: cover;
		}
	}
}
