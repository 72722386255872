.not-found-text {
	text-align: center;
	padding: 20px 0;
	color: #f00;
	i {
		font-size: 30px;
	}
	p {
		font-size: 20px;
		text-transform: capitalize;
	}
}
