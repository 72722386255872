.my-profile-wrap {
	max-width: 650px;
	margin: 0 auto;
	width: 100%;
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.09);
	.my-profile-box {
		.profile-img {
			width: 150px;
			height: 150px;
			margin: 0 auto 20px;
			overflow: hidden;
			border-radius: 50%;
			border: 4px solid #fff;
			box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.my-profile-list {
			padding: 13px 0;
			.my-profile-list-lft {
				flex: 0 0 160px;
				max-width: 160px;
				font-weight: 700;
				font-size: 16px;
				text-transform: capitalize;
				line-height: 1;
			}
			.my-profile-list-rgt {
				flex: 1 0 0%;
				font-weight: 600;
				font-size: 16px;
				line-height: 1;
			}
			+ {
				.my-profile-list {
					border-top: 1px dashed #dfdfdf;
				}
			}
		}
	}
}
.my-profile-edit {
	position: absolute;
	right: 0;
	top: 0;
	.profile-edit-btn {
		background-color: #fff;
		box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
		width: 35px;
		height: 35px;
		line-height: 35px;
		color: #4b8e20;
		font-size: 15px;
		border-radius: 50%;
		text-align: center;
	}
}
