@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
}

@media only screen and (max-width: 1204px) {
}

@media only screen and (max-width: 1135px) {
	.sendus-massege-rgt {
		.sendus-massege-inner {
			width: 450px;
		}
	}
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
	.sendus-massege-wrap {
		.sendus-massege-lft,
		.sendus-massege-rgt {
			flex: 0 0 100%;
			max-width: 100%;
		}
		.sendus-massege-rgt {
			.sendus-massege-inner {
				width: 720px;
				margin: 30px auto;
			}
		}
	}
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
	.sendus-massege-wrap {
		.sendus-massege-rgt {
			.sendus-massege-inner {
				width: 550px;
			}
		}
	}
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 575px) {
	.sendus-massege-wrap {
		.sendus-massege-rgt {
			.sendus-massege-inner {
				width: 450px;
			}
		}
	}
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
