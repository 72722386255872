// @import 'assets/styles/style.scss';
@import 'assets/styles/variable.scss';
.topHeader {
	background: $headerbtmBg;
	position: relative;
	z-index: 2;
	// padding: 25px 0;
}

.callTimeMail {
	padding: 0 16px;
}

.callTimeMail {
	h5 {
		padding: 0;
		font-size: 14px;
		color: #00bbee;
		letter-spacing: 0.2px;
	}
}

.callTimeMail p {
	padding: 0;
	font-size: 14px;
	color: #000;
	font-weight: 600;
}

.callTimeMail p a {
	color: #000;
}

.ctmDesc {
	padding-left: 12px;
}

.logo {
	flex: 0 0 150px;
	max-width: 150px;
	padding: 0 15px;
	position: relative;
	transition: all 0.3s ease-in-out;
	a {
		display: block;
		width: 100%;
		position: absolute;
		left: 0;
		top: 7px;
		background: $headerbtmBg;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			background: $headerbtmBg;
			clip-path: polygon(50% 30%, 0 0, 100% 0);
			height: 100%;
			width: 100%;
			top: 100%;
		}
		.logo-wrap {
			display: block;
			max-width: 110px;
			margin: 0 auto;
			transition: all 0.3s ease-in-out;
		}
	}
}

.srcOffer {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	max-width: auto;
	padding: 0 15px;
}

.top-contact a {
	display: flex;
	color: #535353;
	font-weight: 600;
	font-size: 16px;
	align-items: center;
}

.top-contact a i {
	padding-right: 10px;
	font-size: 25px;
	line-height: 1;
	color: #29538f;
}

.btmHeaderTop {
	padding: 15px 0;
}

.menu-responsive {
	display: none;
}

.navigation {
	ul {
		// margin-left: 40px;
		li {
			flex-basis: 1;
			flex-grow: 1;
			max-width: 100%;
			position: relative;
			a {
				color: #fff;
				font-size: 16px;
				display: block;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 0.5px;
				padding: 0 25px;
				font-weight: 600;
				line-height: 3.5;
				font-family: 'Barlow Semi Condensed';
			}
		}
	}
}

.navigation ul li a i.fa-home {
	font-size: 32px;
}

.p-r-0 {
	padding-right: 0;
}

.navigation ul li a.active,
.navigation ul li a:hover {
	color: $primeryHover;
	background: $seconderyColor;
}

.navigation ul li ul.submenu li a {
	font-size: 13px;
	text-align: left;
	line-height: 36px;
	color: #000;
	padding-left: 0;
}

.navigation ul li ul.submenu li a:hover {
	// color: #fff;
	// background: #29538f;
	padding-left: 25px;
}

.navigation ul li ul.submenu {
	position: absolute;
	background: #fff;
	left: -30px;
	width: 280px;
	top: calc(100% + 15px);
	opacity: 0;
	visibility: hidden;
	transition: all 300ms ease-in-out;
	box-shadow: 0 5px 10px 9px rgb(138, 155, 165, 15%);
}

.scroll-on .navigation ul li ul.submenu {
	top: calc(100% + 10px);
}

.navigation ul li:hover ul.submenu {
	opacity: 1;
	visibility: visible;
	left: 0;
}

.login-area {
	flex: 0 0 200px;
	max-width: 200px;
	padding: 0 15px;
}

.login-area .login-btn {
	line-height: 2.5;
	display: block;
	border: 1px solid #1dcdfe;
	padding: 0 15px;
	color: #1dcdfe;
	font-size: 15px;
	font-weight: 500;
	text-align: center;
}

.main-background {
	background: rgb(20, 40, 47);
	background: linear-gradient(137.56deg, rgba(27, 27, 29, 1) 26.38%, #0c3642 97.6%);
}

.pt_90 {
	padding-top: 90px;
}

.min-100vh {
	min-height: 100vh;
}

.start-header {
	opacity: 1;
	// transform: translateY(0);
	padding: 0;
	transition: all 0.3s ease-out;
	box-shadow: 0 5px 10px 9px rgb(138 155 165 / 8%);
	&.scroll-on {
		box-shadow: 0 5px 10px 9px rgba(138, 155, 165, 0.15);
		// padding: 0;
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 5;
		// background: #fff;
		.logo {
			.logo-wrap {
				max-width: 93px;
			}
		}
	}
}

.after-login-wrap {
	.after-login {
		cursor: pointer;
		.user-img {
			display: block;
			max-width: 30px;
			height: 30px;
			object-fit: cover;
			border-radius: 50%;
			overflow: hidden;
			img {
				height: 100%;
			}
		}
		h5 {
			color: #000;
			font-size: 13px;
			padding: 0 5px;
			margin: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.after-login-icon {
			display: block;
			color: #000;
			font-size: 13px;
		}
	}
	.after-login-menu-wrap {
		position: absolute;
		right: 0;
		width: 100%;
		min-width: 160px;
		background: #fff;
		top: 150%;
		padding: 5px;
		border-radius: 5px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.2s ease-in-out;
		box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
		z-index: 3;
		ul {
			li {
				a {
					display: block;
					color: #000;
					font-size: 15px;
					text-transform: capitalize;
					padding: 5px;
					font-weight: 500;
					&:hover {
						color: #78bc4c;
					}
				}
			}
		}
	}
	&:hover {
		.after-login-menu-wrap {
			transition: all 0.2s ease-in-out;
			opacity: 1;
			visibility: visible;
			top: 120%;
		}
	}
}

.top-header-bar {
	background: #fff;
	padding: 10px 0;
}

.top-rgt-wrap {
	.top-small-menu {
		li {
			a {
				color: #fff;
				display: block;
				font-size: 13px;
			}
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
}

.log-reg-nav {
	ul {
		li {
			a {
				color: #000;
				font-size: 15px;
				display: block;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 0.5px;
				padding: 0 10px;
				font-weight: 600;
				line-height: 2;
				border-radius: 5px;
				font-family: 'Barlow Semi Condensed';
				&:hover {
					color: $primeryColor;
				}
				&.reg-nav {
					background-color: $primeryColor;
					color: #fff;
					&:hover {
						background: $primeryHover;
						color: #fff;
					}
				}
			}
			&:not(:last-child) {
				margin-right: 8px;
			}
		}
	}
}

.header-language {
	position: relative;
	.lnguge-top-select {
		padding-left: 30px;
		height: 32px !important;
		color: $tartiaryColor;
		font-weight: 500;
		background-size: 10px;
	}
	.glob-icon {
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.style-rtl {
	.top-header-bar {
		.header-language {
			.lnguge-top-select {
				background: rgba(255, 255, 255, 0) url(../../assets/images/angle-down-solid.svg) center left 10px no-repeat;
				background-size: 10px;
			}
			.glob-icon {
				left: auto;
				right: 0;
			}
		}
		.log-reg-nav {
			ul {
				li {
					&:not(:last-child) {
						margin-right: 0px;
						margin-left: 8px;
					}
				}
			}
		}
	}
	.after-login-menu-wrap {
		max-width: 145px;
		right: auto;
		left: 0;
	}
}
.cart-wrap {
	.cart-wrap-icon {
		position: relative;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		line-height: 30px;
		text-align: center;
		color: $primeryColor;
		font-size: 18px;
		margin-right: 17px;
		.cart-wrap-total {
			position: absolute;
			display: block;
			height: 20px;
			width: 20px;
			line-height: 20px;
			font-size: 10px;
			text-align: center;
			border-radius: 50%;
			background: #fff;
			color: $primeryColor;
			right: -8px;
			top: -8px;
			box-shadow: 0px 0px 4px 2px rgba(74, 75, 75, 0.3);
			font-weight: 500;
		}
	}
}
