@import 'assets/styles/variable.scss';
// Fund Details Page Start
.sec-heading-three {
	h3 {
		font-size: 40px;
		font-family: 'Barlow Semi Condensed';
		color: #3a3a3b;
		font-weight: 600;
		text-transform: uppercase;
	}
}

.found-sliderbox {
	margin-bottom: 40px;
	#big {
		margin-bottom: 20px;
		.foundr-img {
			img {
				max-height: 500px;
				min-height: 500px;
				object-fit: cover;
			}
		}
	}
	#thumbs {
		.foundr-img {
			img {
				max-height: 140px;
				min-height: 140px;
				object-fit: cover;
			}
		}
	}
	#big,
	#thumbs {
		.owl-nav {
			.owl-next {
				right: 0;
			}
			.owl-prev {
				left: 0;
			}
			.owl-next,
			.owl-prev {
				position: absolute;
				top: 50%;
				margin: 0;
				transform: translateY(-50%);
				background: $seconderyColor;
				height: 40px;
				width: 40px;
				border-radius: 50%;
				color: #fff;
				&.disabled {
					opacity: 0.5;
				}
			}
		}
	}
	.image-gallery {
		.image-gallery-content {
			.image-gallery-slide-wrapper {
				.image-gallery-icon {
					height: 50px;
					width: 50px;
					line-height: 50px;
					background: #78bc4c;
					border-radius: 50%;
					padding: 0;
					box-shadow: none;
					filter: none;
					&:hover {
						color: #fff;
					}
					.image-gallery-svg {
						height: 50px;
						width: 50px;
					}
				}
				.image-gallery-swipe {
					margin-bottom: 20px;
					.image-gallery-slides {
						.image-gallery-image {
							max-height: 70vh;
							min-height: 70vh;
							background: #f8fbf6;
							object-fit: cover;
						}
					}
				}
			}
			.image-gallery-thumbnails-wrapper {
				.image-gallery-thumbnails {
					.image-gallery-thumbnail {
						border-radius: 10px;
						overflow: hidden;
						.image-gallery-thumbnail-image {
							height: 70px;
							object-fit: cover;
						}
						&:hover,
						&.active {
							border-color: #78bc4c;
						}
					}
				}
			}
		}
	}
}

.campaigner-slider {
	.owl-stage-outer {
		padding: 15px 0;
	}
}

.campaigner-box {
	background: #fff;
	box-shadow: 0px 0px 12.8px 3.2px rgba(74, 75, 75, 0.18);
	padding: 20px;
	.campaigner-box-lft {
		flex: 0 0 80px;
		max-width: 80px;
		.about-partner-box-img {
			display: block;
			img {
				min-height: 80px;
				max-height: 80px;
				object-fit: cover;
			}
		}
	}
	.campaigner-box-rgt {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		padding-left: 15px;
		p {
			font-weight: 600;
		}
		h4 {
			color: #78bc4c;
			font-size: 16px;
		}
		ul {
			li {
				font-weight: 600;
				i {
					margin-right: 6px;
				}
			}
		}
	}
}

.donate-btn-wrap {
	.donate-btn {
		background-color: #4b8e20;
		line-height: 45px;
		height: 45px;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 15px;
		border: none;
		box-shadow: none;
		outline: none !important;
		font-family: 'Barlow Semi Condensed';
		font-weight: 600;
		width: 100%;
		i {
			margin-right: 6px;
		}
	}
}

.donate-btn-wrap2 {
	.donate-btn2 {
		background-color: #fff;
		color: #000;
		font-size: 11px;
		border-radius: 5px;
		padding: 0 7px;
		border: 1px solid #000;
		box-shadow: none;
		outline: none !important;
		font-family: 'Barlow Semi Condensed';
		font-weight: 600;
		height: 45px;
		line-height: 1;
		width: 100%;
		i {
			margin-right: 6px;
			font-size: 16px;
		}
	}
}

.facebook-btn-wrap {
	// margin-top: 20px;
	.facebook-btn {
		background-color: #3b5998;
		height: 45px;
		line-height: 45px;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 25px;
		border: none;
		box-shadow: none;
		outline: none !important;
		font-family: 'Barlow Semi Condensed';
		width: 100%;
		i {
			margin-right: 6px;
		}
	}
	p {
		font-weight: 600;
		font-size: 12px;
		color: #3b5998;
		margin-top: 2px;
		text-align: center;
	}
}

.total-donete {
	margin: 30px 0 25px 0;
	h3 {
		font-size: 50px;
		font-family: 'Barlow Semi Condensed';
		text-align: center;
		padding-bottom: 5px;
	}
	p {
		color: #3a3a3b;
		font-weight: 600;
		text-align: center;
	}
}

.supoters-cal-wrap {
	ul {
		li {
			display: flex;
			align-items: center;
			padding: 10px 0;
			.supoters-cal-pic {
				display: block;
				width: 35px;
			}
			p {
				color: #3a3a3b;
				font-weight: 600;
				margin-left: 10px;
			}
		}
	}
}

.share-btn-wrap {
	margin: 20px 0;
	.share-btn {
		background-color: #bcbcbd;
		line-height: 2.5;
		color: #fff;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 25px;
		border: none;
		box-shadow: none;
		outline: none !important;
		font-family: 'Barlow Semi Condensed';
		width: 100%;
		i {
			margin-right: 6px;
		}
	}
}

.rgt-green-head-box {
	box-shadow: 0px 0px 12.22px 0.78px rgba(74, 75, 75, 0.24);
	background-color: #fff;
	.rgt-green-head {
		background-color: #78bc4c;
		padding: 12px;
		ul {
			li {
				display: flex;
				align-items: center;
				.rgt-green-pic {
					display: block;
					width: 35px;
				}
				p {
					color: #fff;
					font-weight: 600;
					margin-left: 10px;
					font-size: 18px;
				}
			}
		}
	}
	.rgt-green-body {
		padding: 15px;
		.start-donate {
			display: block;
			color: $tartiaryColor;
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 13px;
			i {
				margin-right: 10px;
				color: $seconderyColor;
			}
		}
		.rgt-green-more {
			margin-top: 3px;
			text-align: center;
			.show-more {
				background: transparent;
				border: none;
				box-shadow: none;
				outline: none !important;
				color: #78bc4c;
				border-bottom: 1px solid #78bc4c;
				font-weight: 600;
				font-size: 14px;
			}
		}
	}
	+ {
		.rgt-green-head-box {
			margin-top: 20px;
		}
	}
}

.donetar-wrap {
	.donetar-name-wrap {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
		.donetar-name {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
			padding-left: 13px;
			font-size: 16px;
			font-weight: 700;
			text-transform: capitalize;
		}
	}
	.donetar-amount {
		flex: 0 0 auto;
		width: auto;
		max-width: none;
		font-size: 18px;
		font-weight: 700;
		text-transform: capitalize;
		color: $seconderyColor;
	}
	+ {
		.donetar-wrap {
			margin-top: 20px;
		}
	}
}

.document-download-list {
	.download-list-box {
		a {
			display: block;
			background: #fff;
			border-radius: 10px;
			box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
			text-align: center;
			padding: 4px;
			font-size: 100px;
			color: #78bc4c;
		}
	}
}

.image-list {
	.image-list-box {
		img {
			background: #fff;
			box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
			padding: 4px;
			border-radius: 10px;
			max-height: 150px;
			min-height: 150px;
			object-fit: cover;
		}
	}
}

.comments-wrap {
	.comments-wrap-list {
		.wrap-list-lft {
			flex: 0 0 54px;
			width: 54px;
			span {
				img {
					border-radius: 50%;
				}
			}
			.profile-pic-wrap {
				flex: 0 0 54px;
				max-width: 54px;
				.name-ltr {
					width: 54px;
					height: 54px;
					font-size: 28px;
				}
				.profile-pic {
					img {
						height: 54px;
						width: 54px;
					}
				}
			}
		}
		.wrap-list-rgt {
			flex: 1 0;
			padding-left: 1.3rem;
			.list-rgt-top {
				h5 {
					color: #3a3a3b;
					font-size: 16px;
					padding: 0;
					font-weight: bold;
					margin-right: 1em;
				}
			}
			.list-rgt-btm {
				margin-top: 5px;
			}
		}
		+ {
			.comments-wrap-list {
				margin-top: 15px;
				padding-top: 15px;
				border-top: 1px dashed #d6d6d6;
			}
		}
	}
	.cmnts-input-wrap {
		position: relative;
		margin: 10px 0 30px 0;
		border: 1px solid #ced4da;
		border-radius: 20px;
		.cmnts-input-style {
			border-radius: 20px;
			height: 46px;
			padding: 0px 8px;
			border: none;
			resize: none;
		}
		.msg-type {
			padding: 5px 47px 5px 5px;
		}
		.cmnts-input-btn-wrap {
			position: absolute;
			right: 10px;
			top: 50%;
			display: block;
			transform: translateY(-50%);
			.cmnts-input-btn {
				height: 38px;
				width: 38px;
				border-radius: 50%;
				border: none;
				box-shadow: none;
				outline: none !important;
			}
		}
	}
	.not-comments-wrap {
		margin-top: 60px;
	}
}

.not-comments-wrap {
	text-align: center;
	color: #d3d1d1;
	// margin-top: 60px;
	i {
		font-size: 40px;
	}
	p {
		font-size: 18px;
		text-transform: capitalize;
	}
}

.top-sticky {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -ms-sticky;
	position: -o-sticky;
	position: sticky;
	top: 70px;
}

// Fund Details Page End
// for RTL
.style-rtl {
	.donate-btn-wrap {
		.donate-btn {
			i {
				margin-right: 0;
				margin-left: 6px;
			}
		}
	}
	.donate-btn-wrap2 {
		.donate-btn2 {
			i {
				margin-right: 0;
				margin-left: 6px;
			}
		}
	}
	.facebook-btn-wrap {
		.facebook-btn {
			i {
				margin-right: 0;
				margin-left: 6px;
			}
		}
	}
	.supoters-cal-wrap {
		ul {
			li {
				p {
					margin-left: 0;
					margin-right: 10px;
				}
			}
		}
	}
	.share-btn-wrap {
		.share-btn {
			i {
				margin-right: 0;
				margin-left: 6px;
			}
		}
	}
	.rgt-green-head-box {
		.rgt-green-head {
			ul {
				li {
					p {
						margin-left: 0;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.rgt-green-head-box {
		.rgt-green-body {
			.start-donate {
				i {
					margin-left: 0;
					margin-right: 10px;
				}
			}
			.rgt-green-more {
				.show-more {
					i {
						margin-right: 6px;
					}
				}
			}
		}
	}
	.donetar-wrap {
		.donetar-name-wrap {
			.donetar-name {
				padding-left: 0;
				padding-right: 13px;
			}
		}
	}
	.raiser-dtls-tab {
		.responsive-tabs-dtls {
			.nav-item {
				&:not(:last-child) {
					margin-right: 0;
					margin-left: 15px;
				}
			}
		}
	}
	.comments-wrap {
		.cmnts-input-wrap {
			.msg-type {
				padding: 5px 5px 5px 47px;
			}
			.cmnts-input-btn-wrap {
				right: auto;
				left: 10px;
			}
		}
	}
	.comments-wrap {
		.comments-wrap-list {
			.wrap-list-rgt {
				margin-left: 0;
				margin-right: 1.3rem;
				.list-rgt-top {
					h5 {
						font-weight: bold;
						margin-right: 0;
						margin-left: 1em;
					}
				}
			}
		}
	}
}

.fb-share-btn {
	width: 100%;
}

.donet-view-dtls {
	p {
		font-weight: 700;
		font-size: 14px;
		text-transform: capitalize;
		line-height: 1;
		color: #3a3a3b;
		margin-bottom: 10px;
		strong {
			margin-left: 0.25rem;
		}
	}
}

.image-desc {
	margin-top: 1rem;
	p {
		font-weight: 600;
	}
}

.raiser-name {
	h4 {
		color: #3a3a3b;
	}
}
